package studio.goodegg.capsule.repositories

import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.Episode
import studio.goodegg.capsule.PodcastResult
import studio.goodegg.capsule.SearchResult
import studio.goodegg.capsule.api.clients.DiscoveryClient
import studio.goodegg.capsule.utils.Failed
import studio.goodegg.capsule.utils.Success

interface SearchRepository {
    suspend fun search(q: String): SearchResult
    suspend fun searchPodcasts(q: String): List<PodcastResult>
    suspend fun searchEpisodes(q: String): List<Episode>
}

@Inject
internal class SearchRepositoryImpl(private val discoveryClient: DiscoveryClient) :
    SearchRepository {
    override suspend fun search(q: String): SearchResult {
        return when (val result = discoveryClient.search(q)) {
            is Failed -> SearchResult(emptyList(), emptyList())
            is Success -> result.data
        }
    }

    override suspend fun searchPodcasts(q: String): List<PodcastResult> {
        return when (val result = discoveryClient.podcastSearch(q)) {
            is Failed -> emptyList()
            is Success -> result.data
        }
    }

    override suspend fun searchEpisodes(q: String): List<Episode> {
        return when (val result = discoveryClient.episodeSearch(q)) {
            is Failed -> emptyList()
            is Success -> result.data
        }
    }
}