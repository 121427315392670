package studio.goodegg.capsule.domain.podcast

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.take
import me.tatarka.inject.annotations.Component
import me.tatarka.inject.annotations.Provides
import studio.goodegg.capsule.Podcast
import studio.goodegg.capsule.repositories.PodcastRepository

@Component
interface PodcastDomainComponent {
    @Provides
    fun providesGetPodcastInteractor(podcastsRepository: PodcastRepository): GetPodcastInteractor =
        GetPodcastInteractor { slug -> podcastsRepository.getPodcast(slug) }

    @Provides
    fun providesObservePodcastInteractor(podcastsRepository: PodcastRepository): ObservePodcastInteractor =
        ObservePodcastInteractor { podcastsRepository.observePodcast(it) }

    @Provides
    fun providesObserveEpisodesInteractor(podcastsRepository: PodcastRepository): ObserveEpisodesInteractor =
        ObserveEpisodesInteractor { slugs, limit ->
            podcastsRepository.observeEpisodes(slugs, limit)
        }
}
