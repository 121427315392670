package studio.goodegg.capsule.base.main

import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlSchema
import com.slack.circuit.foundation.Circuit
import io.ktor.client.HttpClient
import kotlin.Unit
import kotlinx.coroutines.CoroutineDispatcher
import me.tatarka.inject.`internal`.LazyMap
import me.tatarka.inject.`internal`.ScopedComponent
import studio.goodegg.capsule.api.clients.DiscoveryClient
import studio.goodegg.capsule.core.playback.Player
import studio.goodegg.capsule.db.CapsuleDbCreator
import studio.goodegg.capsule.details.podcast.PodcastDetailsPresenterFactory
import studio.goodegg.capsule.domain.playback.ObserveAllPlayedInteractor
import studio.goodegg.capsule.domain.playback.ObserveNowPlayingInteractor
import studio.goodegg.capsule.domain.playback.PauseInteractor
import studio.goodegg.capsule.domain.playback.PlayInteractor
import studio.goodegg.capsule.domain.playback.ResumePlaybackInteractor
import studio.goodegg.capsule.domain.subscription.AddSubscriptionInteractor
import studio.goodegg.capsule.domain.subscription.DeleteSubscriptionInteractor
import studio.goodegg.capsule.domain.subscription.ObserveAllSubscriptionsInteractor
import studio.goodegg.capsule.inbox.InboxPresenterFactory
import studio.goodegg.capsule.listen.ListenPresenterFactory
import studio.goodegg.capsule.player.PlayerPresenterFactory
import studio.goodegg.capsule.repositories.PlayedItemsRepository
import studio.goodegg.capsule.repositories.PodcastRepository
import studio.goodegg.capsule.repositories.SearchRepository
import studio.goodegg.capsule.repositories.SubscriptionsRepository
import studio.goodegg.capsule.resume.ResumePresenterFactory
import studio.goodegg.capsule.search.SearchPresenterFactory
import studio.goodegg.capsule.subscriptions.SubscriptionsPresenterFactory
import studio.goodegg.capsule.trending.TrendingPresenterFactory

public fun JsUiComponent.Companion.create(appComponent: JsAppComponent): JsUiComponent =
    InjectJsUiComponent(appComponent)

public class InjectJsUiComponent(
  appComponent: JsAppComponent,
) : JsUiComponent(appComponent), ScopedComponent {
  override val _scoped: LazyMap = LazyMap()

  private val observeAllSubscriptionsInteractor: ObserveAllSubscriptionsInteractor
    get() = providesObserveAllSubscriptionsInteractor(
      subscriptionsRepository = subscriptionsRepository
    )

  private val subscriptionsRepository: SubscriptionsRepository
    get() {
      require(appComponent is ScopedComponent)
      return appComponent._scoped.get("studio.goodegg.capsule.repositories.SubscriptionsRepository") {
        appComponent.providesSubscriptionsRepository(
          capsuleDb = capsuleDbCreator,
          ioDispatcher = coroutineDispatcher
        )
      }
    }

  private val capsuleDbCreator: CapsuleDbCreator
    get() {
      require(appComponent is ScopedComponent)
      return appComponent._scoped.get("studio.goodegg.capsule.db.CapsuleDbCreator") {
        appComponent.providesDbCreator(
          schema = sqlSchemaQueryResult_AsyncValueUnit,
          driver = appComponent._scoped.get("app.cash.sqldelight.db.SqlDriver") {
            appComponent.providesDbDriver(
              schema = sqlSchemaQueryResult_AsyncValueUnit
            )
          }
        )
      }
    }

  private val sqlSchemaQueryResult_AsyncValueUnit: SqlSchema<QueryResult.AsyncValue<Unit>>
    get() {
      require(appComponent is ScopedComponent)
      return appComponent._scoped.get("app.cash.sqldelight.db.SqlSchema<app.cash.sqldelight.db.QueryResult.AsyncValue<kotlin.Unit>>") {
        appComponent.providesDatabaseSchema()
      }
    }

  private val coroutineDispatcher: CoroutineDispatcher
    get() {
      require(appComponent is ScopedComponent)
      return appComponent._scoped.get("@IoDispatcher" + "kotlinx.coroutines.CoroutineDispatcher") {
        appComponent.providesIoDispatcher()
      }
    }

  private val podcastRepository: PodcastRepository
    get() {
      require(appComponent is ScopedComponent)
      return appComponent._scoped.get("studio.goodegg.capsule.repositories.PodcastRepository") {
        appComponent.providesPodcastRepository(
          podcastClient =
              appComponent._scoped.get("studio.goodegg.capsule.api.clients.PodcastClient") {
            appComponent.providesPodcastClient(
              httpClient = httpClient
            )
          },
          podcastQueries = capsuleDbCreator,
          ioDispatcher = coroutineDispatcher
        )
      }
    }

  private val httpClient: HttpClient
    get() {
      require(appComponent is ScopedComponent)
      return appComponent._scoped.get("io.ktor.client.HttpClient") {
        appComponent.providesKtorClient()
      }
    }

  private val observeNowPlayingInteractor: ObserveNowPlayingInteractor
    get() = providesObserveNowPlayingInteractor(
      playedItemsRepository = playedItemsRepository,
      player = player
    )

  private val playedItemsRepository: PlayedItemsRepository
    get() {
      require(appComponent is ScopedComponent)
      return appComponent._scoped.get("studio.goodegg.capsule.repositories.PlayedItemsRepository") {
        appComponent.providesPlayedRepository(
          dbCreator = capsuleDbCreator,
          ioDispatcher = coroutineDispatcher
        )
      }
    }

  private val player: Player
    get() {
      require(appComponent is ScopedComponent)
      return appComponent._scoped.get("studio.goodegg.capsule.core.playback.Player") {
        appComponent.providesPlayer()
      }
    }

  private val pauseInteractor: PauseInteractor
    get() = providesPauseInteractor(
      player = player
    )

  private val resumePlaybackInteractor: ResumePlaybackInteractor
    get() = providesResumeInteractor(
      player = player
    )

  private val playInteractor: PlayInteractor
    get() = providesPlayInteractor(
      player = player
    )

  private val observeAllPlayedInteractor: ObserveAllPlayedInteractor
    get() = providesObserveAllPlayedInteractor(
      playedItemsRepository = playedItemsRepository
    )

  private val discoveryClient: DiscoveryClient
    get() {
      require(appComponent is ScopedComponent)
      return appComponent._scoped.get("studio.goodegg.capsule.api.clients.DiscoveryClient") {
        appComponent.providesDiscoveryClient(
          httpClient = httpClient
        )
      }
    }

  private val addSubscriptionInteractor: AddSubscriptionInteractor
    get() = providesAddSubscriptionInteractor(
      subscriptionsRepository = subscriptionsRepository
    )

  private val deleteSubscriptionInteractor: DeleteSubscriptionInteractor
    get() = providesDeleteSubscriptionInteractor(
      subscriptionsRepository = subscriptionsRepository
    )

  private val searchRepository: SearchRepository
    get() {
      require(appComponent is ScopedComponent)
      return appComponent._scoped.get("studio.goodegg.capsule.repositories.SearchRepository") {
        appComponent.providesSearchRepository(
          podcastClient = discoveryClient
        )
      }
    }

  override val circuit: Circuit
    get() {
      require(appComponent is ScopedComponent)
      return _scoped.get("com.slack.circuit.foundation.Circuit") {
        providesCircuit(
          inboxPresenterFactory = InboxPresenterFactory(
            observeAllSubscriptionsInteractor = observeAllSubscriptionsInteractor,
            observeEpisodesInteractor = providesObserveEpisodesInteractor(
              podcastsRepository = podcastRepository
            ),
            observeNowPlaying = observeNowPlayingInteractor,
            pauseInteractor = pauseInteractor,
            resumePlaybackInteractor = resumePlaybackInteractor,
            playInteractor = playInteractor,
            observeAllPlayedInteractor = observeAllPlayedInteractor
          ),
          trendingPresenterFactory = TrendingPresenterFactory(
            getTrendingInteractor = providesGetTrendingInteractor(
              trendingRepository =
                  appComponent._scoped.get("studio.goodegg.capsule.repositories.TrendingRepository") {
                appComponent.providesTrendingRepository(
                  podcastClient = discoveryClient
                )
              }
            ),
            addSubscriptionInteractor = addSubscriptionInteractor,
            observeAllSubscriptionsInteractor = observeAllSubscriptionsInteractor,
            deleteSubscriptionInteractor = deleteSubscriptionInteractor
          ),
          subscriptionsPresenterFactory = SubscriptionsPresenterFactory(
            observeSubscriptionInteractor = observeAllSubscriptionsInteractor,
            deleteSubscriptionInteractor = deleteSubscriptionInteractor
          ),
          playerPresenterFactory = PlayerPresenterFactory(
            playInteractor = playInteractor,
            pauseInteractor = pauseInteractor,
            stopInteractor = providesStopInteractor(
              player = player
            ),
            resumeInteractor = resumePlaybackInteractor,
            nowPlayingInteractor = observeNowPlayingInteractor,
            observePlayStateInteractor = providesObservePlayStateInteractor(
              player = player
            ),
            lastPlayedInteractor = providesGetLastPlayedInteractor(
              playedItemsRepository = playedItemsRepository,
              podcastRepository = podcastRepository,
              player = player
            )
          ),
          listenPresenterFactory = ListenPresenterFactory(),
          podcastDetailsPresenterFactory = PodcastDetailsPresenterFactory(
            observePodcastInteractor = providesObservePodcastInteractor(
              podcastsRepository = podcastRepository
            ),
            playInteractor = playInteractor,
            observeNowPlaying = observeNowPlayingInteractor,
            pauseInteractor = pauseInteractor,
            resumePlaybackInteractor = resumePlaybackInteractor,
            observeSubscriptionInteractor = providesObserveSubscriptionInteractor(
              subscriptionsRepository = subscriptionsRepository
            ),
            deleteSubscriptionInteractor = deleteSubscriptionInteractor,
            addSubscriptionInteractor = addSubscriptionInteractor,
            observeAllPlayedInteractor = observeAllPlayedInteractor
          ),
          resumePresenterFactory = ResumePresenterFactory(
            playInteractor = playInteractor,
            observeNowPlaying = observeNowPlayingInteractor,
            pauseInteractor = pauseInteractor,
            resumePlaybackInteractor = resumePlaybackInteractor,
            observeInProgressInteractor = providesObserveInProgressInteractor(
              playedItemsRepository = playedItemsRepository,
              podcastRepository = podcastRepository
            ),
            markAsPlayedInteractor = providesMarkAsPlayedInteractor(
              playedItemsRepository = playedItemsRepository
            ),
            unMarkAsPlayedInteractor = providesMarkAsUnPlayedInteractor(
              playedItemsRepository = playedItemsRepository
            )
          ),
          searchPresenterFactory = SearchPresenterFactory(
            searchPodcastsInteractor = providesSearchPodcastsInteractor(
              trendingRepository = searchRepository
            ),
            searchEpisodesInteractor = providesSearchEpisodesInteractor(
              trendingRepository = searchRepository
            ),
            observeNowPlaying = observeNowPlayingInteractor,
            pauseInteractor = pauseInteractor,
            resumePlaybackInteractor = resumePlaybackInteractor,
            playInteractor = playInteractor,
            observeAllSubscriptionsInteractor = observeAllSubscriptionsInteractor,
            addSubscriptionInteractor = addSubscriptionInteractor,
            deleteSubscriptionInteractor = deleteSubscriptionInteractor
          )
        )
      }
    }
}
