package studio.goodegg.capsule.search

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.slack.circuit.retained.collectAsRetainedState
import com.slack.circuit.retained.rememberRetained
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.Navigator
import com.slack.circuit.runtime.presenter.Presenter
import com.slack.circuit.runtime.screen.Screen
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.joinAll
import kotlinx.coroutines.launch
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.Episode
import studio.goodegg.capsule.PodcastResult
import studio.goodegg.capsule.PodcastSubscription
import studio.goodegg.capsule.PlayState
import studio.goodegg.capsule.domain.playback.ObserveNowPlayingInteractor
import studio.goodegg.capsule.domain.playback.PauseInteractor
import studio.goodegg.capsule.domain.playback.PlayInteractor
import studio.goodegg.capsule.domain.playback.ResumePlaybackInteractor
import studio.goodegg.capsule.domain.search.SearchEpisodesInteractor
import studio.goodegg.capsule.domain.search.SearchPodcastsInteractor
import studio.goodegg.capsule.domain.subscription.AddSubscriptionInteractor
import studio.goodegg.capsule.domain.subscription.DeleteSubscriptionInteractor
import studio.goodegg.capsule.domain.subscription.ObserveAllSubscriptionsInteractor
import studio.goodegg.capsule.navigation.PodcastDetailsScreen
import studio.goodegg.capsule.navigation.SearchScreen

@Inject
class SearchPresenterFactory(
    private val searchPodcastsInteractor: SearchPodcastsInteractor,
    private val searchEpisodesInteractor: SearchEpisodesInteractor,
    private val observeNowPlaying: ObserveNowPlayingInteractor,
    private val pauseInteractor: PauseInteractor,
    private val resumePlaybackInteractor: ResumePlaybackInteractor,
    private val playInteractor: PlayInteractor,
    private val observeAllSubscriptionsInteractor: ObserveAllSubscriptionsInteractor,
    private val addSubscriptionInteractor: AddSubscriptionInteractor,
    private val deleteSubscriptionInteractor: DeleteSubscriptionInteractor,
) : Presenter.Factory {
    override fun create(
        screen: Screen,
        navigator: Navigator,
        context: CircuitContext,
    ): Presenter<*>? {
        return when (screen) {
            is SearchScreen -> SearchPresenter(
                navigator,
                searchPodcastsInteractor,
                searchEpisodesInteractor,
                observeNowPlaying,
                pauseInteractor,
                resumePlaybackInteractor,
                playInteractor,
                observeAllSubscriptionsInteractor,
                addSubscriptionInteractor,
                deleteSubscriptionInteractor,
            )

            else -> null
        }
    }
}

class SearchPresenter(
    private val navigator: Navigator,
    private val searchPodcastsInteractor: SearchPodcastsInteractor,
    private val searchEpisodesInteractor: SearchEpisodesInteractor,
    private val observeNowPlaying: ObserveNowPlayingInteractor,
    private val pauseInteractor: PauseInteractor,
    private val resumePlaybackInteractor: ResumePlaybackInteractor,
    private val playInteractor: PlayInteractor,
    private val observeAllSubscriptionsInteractor: ObserveAllSubscriptionsInteractor,
    private val addSubscriptionInteractor: AddSubscriptionInteractor,
    private val deleteSubscriptionInteractor: DeleteSubscriptionInteractor,
) : Presenter<SearchUiState> {
    @Composable
    override fun present(): SearchUiState {
        var episodeResults by remember { mutableStateOf<List<Episode>>(emptyList()) }
        var podcastResults by remember { mutableStateOf<List<PodcastResult>>(emptyList()) }
        var searching by remember { mutableStateOf(false) }
        val scope = rememberCoroutineScope()
        var zeroState by remember { mutableStateOf<String?>("What you looking for?") }

        val subscriptions by rememberRetained {
            observeAllSubscriptionsInteractor().map { it.map { sub -> sub.slug }.toSet() }
        }.collectAsRetainedState(emptySet())

        val nowPlaying by remember { observeNowPlaying() }.collectAsState(null)

        fun eventSink(event: SearchUiEvent) {
            when (event) {
                is SearchUiEvent.OpenEpisode -> {
                    // Needs togo to episode
                    navigator.goTo(PodcastDetailsScreen(event.slug))
                }

                is SearchUiEvent.OpenPodcast -> {
                    navigator.goTo(PodcastDetailsScreen(event.slug))
                }

                is SearchUiEvent.Search -> {
                    searching = true
                    episodeResults = emptyList()
                    podcastResults = emptyList()
                    zeroState = null
                    scope.launch {
                        listOf(
                            launch {
                                episodeResults = searchEpisodesInteractor(event.query)
                            },
                            launch {
                                podcastResults = searchPodcastsInteractor(event.query)
                            },
                        ).joinAll()

                        zeroState = if (episodeResults.isEmpty() && podcastResults.isEmpty())
                            "No Results" else null

                        searching = false
                    }
                }

                is SearchUiEvent.Play -> {
                    if (nowPlaying?.episode?.episodeSlug == event.episode.episodeSlug) {
                        when (nowPlaying?.playState) {
                            PlayState.Buffering, PlayState.Playing -> pauseInteractor()
                            PlayState.Paused -> resumePlaybackInteractor()
                            else -> Unit
                        }
                    } else {
                        playInteractor(event.episode, 0)
                    }
                }

                is SearchUiEvent.Subscribe -> scope.launch {
                    if (subscriptions.contains(event.podcastResult.slug))
                        deleteSubscriptionInteractor(event.podcastResult.slug)
                    else
                        addSubscriptionInteractor(
                            PodcastSubscription(
                                event.podcastResult.slug,
                                event.podcastResult.feed,
                                event.podcastResult.title,
                                event.podcastResult.author,
                                event.podcastResult.imageUrl,
                                true,
                                null,
                                false,
                            ),
                        )
                }

                is SearchUiEvent.Download -> {

                }

                is SearchUiEvent.Share -> {

                }
            }
        }

        return SearchUiState(
            searching = searching,
            podcastResults = podcastResults,
            episodeResults = episodeResults,
            zeroState = zeroState,
            nowPlaying = nowPlaying,
            subscriptions = subscriptions,
            eventSink = ::eventSink,
        )
    }
}
