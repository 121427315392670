package io.daio.pancake.components.item

import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.RowScope
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.semantics.Role
import androidx.compose.ui.semantics.role
import androidx.compose.ui.semantics.semantics
import androidx.compose.ui.unit.dp
import io.daio.pancake.foundations.Theme
import io.daio.pancake.layout.Surface
import io.daio.pancake.layout.Tier

/**
 * A building block component to build custom list items.
 *
 * @param modifier The modifier to be applied to the layout of the list item.
 * @param onAction The action to be performed when the list item is clicked.
 * @param leadingIcon The leading item to be displayed in the list item.
 * @param content The main content to be displayed in the list item.
 * @param trailingIcon The trailing item to be displayed in the list item.
 */
@Composable
fun ListItem(
    modifier: Modifier = Modifier,
    onAction: (() -> Unit)? = null,
    containerColor: Color = Theme.colors.surfaceBackground,
    shape: RoundedCornerShape = Theme.shapes.large,
    leadingIcon: (@Composable RowScope.() -> Unit)? = null,
    trailingIcon: (@Composable RowScope.() -> Unit)? = null,
    content: @Composable RowScope.() -> Unit,
) {
    Surface(
        shape = shape,
        color = containerColor,
        modifier =
            modifier
                .heightIn(min = 80.dp),
    ) {
        Tier(
            horizontalAlignment = Alignment.Start,
            modifier =
                Modifier
                    .clickable { onAction?.invoke() }
                    .padding(horizontal = Theme.dimens.size_s)
                    .semantics { role = Role.Button },
        ) {
            leadingIcon?.invoke(this)
            content()
            trailingIcon?.invoke(this)
        }
    }
}
