package studio.goodegg.capsule.search

import com.slack.circuit.runtime.CircuitUiEvent
import studio.goodegg.capsule.Episode
import studio.goodegg.capsule.PodcastResult

sealed class SearchUiEvent : CircuitUiEvent {
    data class OpenPodcast(val slug: String) : SearchUiEvent()
    data class OpenEpisode(val slug: String) : SearchUiEvent()
    data class Subscribe(val podcastResult: PodcastResult) : SearchUiEvent()
    data class Play(val episode: Episode) : SearchUiEvent()
    data class Download(val episode: Episode) : SearchUiEvent()
    data class Share(val episode: Episode) : SearchUiEvent()
    data class Search(val query: String) : SearchUiEvent()
}
