package io.daio.pancake.components.loading

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.size
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import io.daio.pancake.foundations.Theme

@Composable
fun FullScreenLoadingIndicator(
    modifier: Modifier = Modifier,
    strokeWidth: Dp = 3.dp,
    color: Color = Theme.colors.onSurfaceBackground,
) {
    Box(modifier = modifier.fillMaxSize(), contentAlignment = Alignment.Center) {
        LoadingIndicator(
            Modifier.size(Theme.dimens.size_xxl),
            color = color,
            strokeWidth = strokeWidth,
        )
    }
}
