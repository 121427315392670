package studio.goodegg.capsule.listen

import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.size
import androidx.compose.material3.Icon
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.unit.dp
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.screen.Screen
import com.slack.circuit.runtime.ui.Ui
import com.slack.circuit.runtime.ui.ui
import compose.icons.FeatherIcons
import compose.icons.feathericons.DownloadCloud
import compose.icons.feathericons.Headphones
import io.daio.pancake.components.item.ListItem
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyText
import io.daio.pancake.foundations.text.BodyTextLarge
import io.daio.pancake.layout.LazyStack
import studio.goodegg.capsule.navigation.ListenScreen

class ListenScreenUiFactory : Ui.Factory {
    override fun create(
        screen: Screen,
        context: CircuitContext,
    ): Ui<*>? {
        return when (screen) {
            is ListenScreen -> listen()
            else -> null
        }
    }
}

private fun listen() =
    ui<ListenUiState> { state, modifier ->
        Listen(
            state,
            modifier,
        )
    }

@Composable
fun Listen(
    state: ListenUiState,
    modifier: Modifier = Modifier,
) {
    LazyStack(modifier = modifier) {
        item("in-progress") {
            ListenItem(
                "In Progress",
                icon = FeatherIcons.Headphones,
                onClick = {
                    state.eventSink(ListenUiEvent.OpenInProgress)
                },
            )
        }
        item("downloads") {
            ListenItem(
                "Downloads",
                icon = FeatherIcons.DownloadCloud,
                onClick = {},
            )
        }
    }
}


@Composable
private fun ListenItem(
    title: String,
    icon: ImageVector,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
) {
    ListItem(
        modifier = modifier.fillMaxWidth(),
        onAction = onClick,
        leadingIcon = {
            Icon(
                imageVector = icon,
                tint = Theme.colors.onSurfaceBackground,
                contentDescription = "",
                modifier = Modifier.size(24.dp),
            )
        },
    ) {
        BodyText(title)
    }
}