package studio.goodegg.capsule.player

import com.slack.circuit.runtime.CircuitUiEvent

sealed class PlayerUiEvent : CircuitUiEvent {
    data object Play : PlayerUiEvent()
    
    data object Stop : PlayerUiEvent()

    data object Pause : PlayerUiEvent()
}
