package studio.goodegg.capsule.search

import androidx.compose.runtime.Immutable
import com.slack.circuit.runtime.CircuitUiState
import studio.goodegg.capsule.Episode
import studio.goodegg.capsule.PodcastResult
import studio.goodegg.capsule.EpisodeAndPlayState

@Immutable
data class SearchUiState(
    val searching: Boolean,
    val zeroState: String?,
    val subscriptions: Set<String>,
    val nowPlaying: EpisodeAndPlayState?,
    val podcastResults: List<PodcastResult>,
    val episodeResults: List<Episode>,
    val eventSink: (SearchUiEvent) -> Unit,
) : CircuitUiState
