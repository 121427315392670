package io.daio.pancake.components.bottombar

import androidx.compose.animation.animateColorAsState
import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.RowScope
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.size
import androidx.compose.material3.BottomAppBar
import androidx.compose.material3.Icon
import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.runtime.ReadOnlyComposable
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.semantics.Role
import androidx.compose.ui.semantics.role
import androidx.compose.ui.semantics.semantics
import androidx.compose.ui.unit.dp
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.color.contentColorFor
import io.daio.pancake.foundations.text.LinkText
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.Stack
import io.daio.pancake.layout.Tier

@Composable
fun BottomBar(
    modifier: Modifier = Modifier,
    color: Color = Theme.colors.surfaceBold,
    contentColor: Color = contentColorFor(color),
    actions: @Composable RowScope.() -> Unit,
) {
    BottomAppBar(
        containerColor = color,
        contentColor = contentColor,
    ) {
        Tier(
            modifier = modifier.fillMaxSize(),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalAlignment = Alignment.CenterVertically,
        ) {
            actions()
        }
    }
}

@Composable
fun RowScope.BarItem(
    title: String,
    icon: ImageVector?,
    selected: Boolean,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    barItemColors: BarItemColors = BarItemDefaults.colors(),
) {
    Stack(
        spaceBetween = LayoutGap.Tiny,
        verticalAlignment = Alignment.CenterVertically,
        horizontalAlignment = Alignment.CenterHorizontally,
        modifier =
            modifier
                .clickable(onClick = onClick)
                .semantics { role = Role.Button }
                .height(BarItemDefaults.barItemHeight)
                .fillMaxWidth(),
    ) {
        val contentColor =
            if (selected) barItemColors.selectedTextColor else barItemColors.textColor

        icon?.let {
            val scale by animateFloatAsState(targetValue = if (selected) 1.2f else 1f)
            Icon(
                imageVector = icon,
                contentDescription = "Bottom Bar Item $title",
                tint = animateColorAsState(contentColor, label = "animate bar icon").value,
                modifier =
                    Modifier.graphicsLayer {
                        scaleX = scale
                        scaleY = scale
                    }.size(24.dp),
            )
        }
        LinkText(
            text = title,
            textColor = animateColorAsState(contentColor, label = "animate bar text").value,
        )
    }
}

@Immutable
data class BarItemColors(
    val textColor: Color,
    val selectedTextColor: Color,
)

object BarItemDefaults {
    val barItemHeight = 56.dp

    @Composable
    @ReadOnlyComposable
    fun colors(
        textColor: Color = Theme.colors.onSurfaceBold,
        selectedTextColor: Color = Theme.colors.accent,
    ): BarItemColors {
        return BarItemColors(
            textColor = textColor,
            selectedTextColor = selectedTextColor,
        )
    }
}
