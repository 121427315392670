package studio.goodegg.capsule.common.coroutines

import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.Dispatchers
import me.tatarka.inject.annotations.Provides
import studio.goodegg.capsule.common.di.ApplicationScope

actual interface IoDispatcherComponent {
    @Provides
    @ApplicationScope
    @IoDispatcher
    fun providesIoDispatcher(): CoroutineDispatcher = Dispatchers.Default
}