package io.daio.pancake.layout

import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import io.daio.pancake.foundations.Theme

enum class LayoutGap(val size: Dp) {
    None(0.dp),
    Tiny(Theme.dimens.size_xs),
    Small(Theme.dimens.size_s),
    Medium(Theme.dimens.size_m),
    Large(Theme.dimens.size_l),
    ExtraLarge(Theme.dimens.size_xl),
    Huge(Theme.dimens.size_xxl),
    Mega(Theme.dimens.size_xxxl),
}
