package studio.goodegg.capsule.repositories

import kotlinx.coroutines.CoroutineDispatcher
import me.tatarka.inject.annotations.Component
import me.tatarka.inject.annotations.Provides
import studio.goodegg.capsule.api.clients.DiscoveryClient
import studio.goodegg.capsule.api.clients.PodcastClient
import studio.goodegg.capsule.common.coroutines.IoDispatcher
import studio.goodegg.capsule.common.di.ApplicationScope
import studio.goodegg.capsule.db.CapsuleDbCreator

@Component
interface RepositoriesComponent {
    @Provides
    @ApplicationScope
    fun providesSubscriptionsRepository(
        capsuleDb: CapsuleDbCreator,
        @IoDispatcher ioDispatcher: CoroutineDispatcher,
    ): SubscriptionsRepository =
        SubscriptionsRepositoryImpl(capsuleDb, ioDispatcher)

    @Provides
    @ApplicationScope
    fun providesPodcastRepository(
        podcastClient: PodcastClient,
        podcastQueries: CapsuleDbCreator,
        @IoDispatcher ioDispatcher: CoroutineDispatcher,
    ): PodcastRepository =
        PodcastRepositoryImpl(podcastQueries, podcastClient, ioDispatcher)

    @Provides
    @ApplicationScope
    fun providesTrendingRepository(podcastClient: DiscoveryClient): TrendingRepository =
        TrendingRepositoryImpl(podcastClient)

    @Provides
    @ApplicationScope
    fun providesSearchRepository(podcastClient: DiscoveryClient): SearchRepository =
        SearchRepositoryImpl(podcastClient)

    @Provides
    @ApplicationScope
    fun providesPlayedRepository(
        dbCreator: CapsuleDbCreator,
        @IoDispatcher ioDispatcher: CoroutineDispatcher,
    ): PlayedItemsRepository = PlayedRepositoryImpl(dbCreator, ioDispatcher)
}
