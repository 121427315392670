package io.daio.bass

import kotlinx.atomicfu.atomic
import kotlinx.atomicfu.updateAndGet

internal object SingletonBassPlayer {
    private val bassPlayerRef = atomic<BassPlayer?>(null)

    fun getPlayer(context: BassContext): BassPlayer {
        return bassPlayerRef.value ?: bassPlayerRef.updateAndGet {
            if (it != null) {
                return it
            }

            context
                .playerFactory()
                .create(context)
        }!!
    }
}
