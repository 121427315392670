package studio.goodegg.capsule.db

import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlSchema
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flatMapLatest
import kotlinx.coroutines.flow.flow
import me.tatarka.inject.annotations.Component
import me.tatarka.inject.annotations.Provides
import studio.goodegg.capsule.common.di.ApplicationScope

expect class CapsuleDbCreator {
    suspend fun get(): CapsuleDb
}

@OptIn(ExperimentalCoroutinesApi::class)
fun <T> CapsuleDbCreator.asFlow(block: CapsuleDb.() -> Flow<T>): Flow<T> = flow {
    emit(get())
}.flatMapLatest {
    block(it)
}

@Component
interface DatabaseComponent : DbDriverComponent {
    @Provides
    @ApplicationScope
    fun providesDatabaseSchema(): SqlSchema<QueryResult.AsyncValue<Unit>> = CapsuleDb.Schema
}
