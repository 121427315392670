package studio.goodegg.capsule.search

import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.lazy.items
import androidx.compose.material3.HorizontalDivider
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.FocusRequester
import androidx.compose.ui.focus.focusRequester
import androidx.compose.ui.platform.LocalSoftwareKeyboardController
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.screen.Screen
import com.slack.circuit.runtime.ui.Ui
import com.slack.circuit.runtime.ui.ui
import compose.icons.FeatherIcons
import compose.icons.feathericons.Bookmark
import compose.icons.feathericons.Plus
import compose.icons.feathericons.Search
import io.daio.pancake.components.loading.LoadingBar
import io.daio.pancake.components.search.SearchBar
import io.daio.pancake.components.tabs.TabBar
import io.daio.pancake.components.tabs.TabBarItem
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyText
import io.daio.pancake.foundations.text.BodyTextSmall
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.LazyStack
import io.daio.pancake.layout.Stack
import io.daio.pancake.layout.Tier
import kotlinx.coroutines.delay
import studio.goodegg.capsule.PodcastResult
import studio.goodegg.capsule.navigation.SearchScreen
import studio.goodegg.capsule.ui.components.ArtworkTile
import studio.goodegg.capsule.ui.components.EpisodeItem
import studio.goodegg.capsule.ui.components.OutlineIconButton
import studio.goodegg.capsule.ui.components.ZeroStateLayout

class SearchScreenUiFactory : Ui.Factory {
    override fun create(
        screen: Screen,
        context: CircuitContext,
    ): Ui<*>? {
        return when (screen) {
            is SearchScreen -> search()
            else -> null
        }
    }
}

private fun search() =
    ui<SearchUiState> { state, modifier ->
        Search(
            state,
            modifier,
        )
    }

@OptIn(ExperimentalFoundationApi::class)
@Composable
fun Search(
    state: SearchUiState,
    modifier: Modifier = Modifier,
) {
    Box(modifier = modifier.fillMaxSize()) {
        Stack {
            val focusRequester = remember { FocusRequester() }
            val keyboard = LocalSoftwareKeyboardController.current

            var query by remember { mutableStateOf("") }
            Spacer(modifier = Modifier.height(Theme.dimens.size_xxxs))
            SearchBar(
                modifier = Modifier.focusRequester(focusRequester),
                value = query,
                onTrailingIconClicked = {
                    query = ""
                },
                onValueChange = {
                    query = it
                },
                onEnter = {
                    keyboard?.hide()
                    state.eventSink(SearchUiEvent.Search(query))
                },
            )

            LaunchedEffect(Unit) {
                delay(200)
                focusRequester.requestFocus()
            }

            var selectedTab by remember { mutableStateOf(TabBarItem("Podcasts")) }
            TabBar(
                listOf(TabBarItem("Podcasts"), TabBarItem("Episodes")),
                onClick = {
                    selectedTab = it
                },
            )
            if (state.searching)
                LoadingBar(modifier = Modifier.height(1.dp).fillMaxWidth())

            if (state.zeroState != null) {
                ZeroStateLayout(
                    state.zeroState,
                    subtitle = "",
                    icon = FeatherIcons.Search,
                )
            }

            when (selectedTab.title) {
                "Podcasts" -> {
                    LazyStack(
                        spaceBetween = LayoutGap.Small,
                        modifier = Modifier.fillMaxSize(),
                    ) {
                        items(
                            state.podcastResults,
                            key = { it.slug },
                        ) {
                            Stack(modifier = Modifier.animateItemPlacement()) {
                                SearchPodcastItem(
                                    podcast = it,
                                    subscribed = state.subscriptions.contains(it.slug),
                                    onClick = {
                                        state.eventSink(SearchUiEvent.OpenPodcast(it.slug))
                                    },
                                    onSubscribe = {
                                        state.eventSink(SearchUiEvent.Subscribe(it))
                                    },
                                )
                                HorizontalDivider(
                                    color = Theme.colors.onSurfaceBackground.copy(
                                        alpha = .2f,
                                    ),
                                )
                            }
                        }
                    }
                }

                "Episodes" -> {
                    LazyStack(modifier = Modifier.fillMaxSize()) {
                        items(
                            state.episodeResults,
                            key = { it.episodeSlug },
                        ) {
                            Stack(modifier = Modifier.animateItemPlacement()) {
                                EpisodeItem(
                                    episode = it,
                                    nowPlaying = state.nowPlaying,
                                    onPlay = {
                                        state.eventSink(SearchUiEvent.Play(it))
                                    },
                                    onShare = {
                                        state.eventSink(SearchUiEvent.Share(it))
                                    },
                                    onDownload = {
                                        state.eventSink(SearchUiEvent.Download(it))
                                    },
                                )
                                HorizontalDivider(
                                    color = Theme.colors.onSurfaceBackground.copy(
                                        alpha = .2f,
                                    ),
                                )
                            }
                        }
                    }
                }
            }
        }
    }
}

@Composable
private fun SearchPodcastItem(
    podcast: PodcastResult,
    subscribed: Boolean,
    onSubscribe: () -> Unit,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
) {
    Stack(
        modifier = modifier
            .wrapContentHeight()
            .fillMaxWidth()
            .clickable(onClick = onClick),
        spaceBetween = LayoutGap.Small,
    ) {
        Tier(verticalAlignment = Alignment.Top) {
            ArtworkTile(
                size = 76.dp,
                imageUrl = podcast.imageUrl,
            )
            Stack(
                spaceBetween = LayoutGap.Tiny,
                modifier = Modifier.weight(1f),
            ) {
                BodyText(
                    podcast.title,
                    maxLines = 3,
                    overflow = TextOverflow.Ellipsis,
                )

                BodyTextSmall(
                    podcast.author,
                    maxLines = 1,
                    overflow = TextOverflow.Ellipsis,
                )
            }

            OutlineIconButton(
                modifier = Modifier.align(Alignment.CenterVertically).size(38.dp),
                icon = if (subscribed) FeatherIcons.Bookmark else FeatherIcons.Plus,
                onClick = onSubscribe,
            )
        }
    }
}
