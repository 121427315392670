

// Do not edit directly
// Generated on Fri, 07 Jun 2024 06:18:18 GMT

package io.daio.pancake.foundations.text

import androidx.compose.ui.unit.sp

object FontSize {
    val fontBodyDefault = 18.00.sp
    val fontBodyLarge = 24.00.sp
    val fontBodySmall = 14.00.sp
    val fontLink = 12.00.sp
    val fontTitleDefault = 28.00.sp
    val fontTitleLarge = 32.00.sp
    val fontTitleScreen = 40.00.sp
}
