package studio.goodegg.capsule.db

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import kotlin.Any
import kotlin.Long
import kotlin.String
import kotlin.collections.Collection

public class PodcastQueries(
  driver: SqlDriver,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> selectPodcast(slug: String, mapper: (
    slug: String,
    id: Long,
    title: String,
    author: String,
    feed: String,
    collectionId: Long,
    artistId: Long?,
    thumbnail: String,
    imageUrl: String,
    trackCount: Long?,
    primaryGenreName: String,
    summary: String,
    categories: String?,
    feedimage: String?,
  ) -> T): Query<T> = SelectPodcastQuery(slug) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4)!!,
      cursor.getLong(5)!!,
      cursor.getLong(6),
      cursor.getString(7)!!,
      cursor.getString(8)!!,
      cursor.getLong(9),
      cursor.getString(10)!!,
      cursor.getString(11)!!,
      cursor.getString(12),
      cursor.getString(13)
    )
  }

  public fun selectPodcast(slug: String): Query<Dbpodcast> = selectPodcast(slug) { slug_, id, title,
      author, feed, collectionId, artistId, thumbnail, imageUrl, trackCount, primaryGenreName,
      summary, categories, feedimage ->
    Dbpodcast(
      slug_,
      id,
      title,
      author,
      feed,
      collectionId,
      artistId,
      thumbnail,
      imageUrl,
      trackCount,
      primaryGenreName,
      summary,
      categories,
      feedimage
    )
  }

  public fun <T : Any> selectPodcastEpisodes(parent_slug: String, mapper: (
    episodeSlug: String,
    title: String,
    author: String,
    created: Long,
    description: String?,
    image: String?,
    media: String,
    mediaType: String,
    fileSize: Long?,
    duration: Long?,
    parentFeed: String,
    parent_slug: String,
  ) -> T): Query<T> = SelectPodcastEpisodesQuery(parent_slug) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getLong(3)!!,
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6)!!,
      cursor.getString(7)!!,
      cursor.getLong(8),
      cursor.getLong(9),
      cursor.getString(10)!!,
      cursor.getString(11)!!
    )
  }

  public fun selectPodcastEpisodes(parent_slug: String): Query<Dbepisode> =
      selectPodcastEpisodes(parent_slug) { episodeSlug, title, author, created, description, image,
      media, mediaType, fileSize, duration, parentFeed, parent_slug_ ->
    Dbepisode(
      episodeSlug,
      title,
      author,
      created,
      description,
      image,
      media,
      mediaType,
      fileSize,
      duration,
      parentFeed,
      parent_slug_
    )
  }

  public fun <T : Any> selectEpisode(episodeSlug: String, mapper: (
    episodeSlug: String,
    title: String,
    author: String,
    created: Long,
    description: String?,
    image: String?,
    media: String,
    mediaType: String,
    fileSize: Long?,
    duration: Long?,
    parentFeed: String,
    parent_slug: String,
  ) -> T): Query<T> = SelectEpisodeQuery(episodeSlug) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getLong(3)!!,
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6)!!,
      cursor.getString(7)!!,
      cursor.getLong(8),
      cursor.getLong(9),
      cursor.getString(10)!!,
      cursor.getString(11)!!
    )
  }

  public fun selectEpisode(episodeSlug: String): Query<Dbepisode> = selectEpisode(episodeSlug) {
      episodeSlug_, title, author, created, description, image, media, mediaType, fileSize,
      duration, parentFeed, parent_slug ->
    Dbepisode(
      episodeSlug_,
      title,
      author,
      created,
      description,
      image,
      media,
      mediaType,
      fileSize,
      duration,
      parentFeed,
      parent_slug
    )
  }

  public fun <T : Any> selectListEpisodes(
    parent_slug: Collection<String>,
    `value`: Long,
    mapper: (
      episodeSlug: String,
      title: String,
      author: String,
      created: Long,
      description: String?,
      image: String?,
      media: String,
      mediaType: String,
      fileSize: Long?,
      duration: Long?,
      parentFeed: String,
      parent_slug: String,
    ) -> T,
  ): Query<T> = SelectListEpisodesQuery(parent_slug, value) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getLong(3)!!,
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6)!!,
      cursor.getString(7)!!,
      cursor.getLong(8),
      cursor.getLong(9),
      cursor.getString(10)!!,
      cursor.getString(11)!!
    )
  }

  public fun selectListEpisodes(parent_slug: Collection<String>, value_: Long): Query<Dbepisode> =
      selectListEpisodes(parent_slug, value_) { episodeSlug, title, author, created, description,
      image, media, mediaType, fileSize, duration, parentFeed, parent_slug_ ->
    Dbepisode(
      episodeSlug,
      title,
      author,
      created,
      description,
      image,
      media,
      mediaType,
      fileSize,
      duration,
      parentFeed,
      parent_slug_
    )
  }

  public suspend fun insertPodcast(
    slug: String,
    id: Long,
    title: String,
    author: String,
    feed: String,
    collectionId: Long,
    artistId: Long?,
    thumbnail: String,
    imageUrl: String,
    trackCount: Long?,
    primaryGenreName: String,
    summary: String,
    categories: String?,
    feedimage: String?,
  ) {
    driver.execute(-1_543_143_946, """
        |INSERT OR REPLACE INTO dbpodcast (
        |    slug,
        |    id,
        |    title,
        |    author,
        |    feed,
        |    "collectionId",
        |    "artistId",
        |    thumbnail,
        |    "imageUrl",
        |    "trackCount",
        |    "primaryGenreName",
        |    summary,
        |    categories,
        |    feedimage
        |) VALUES (?, ?,?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)
        """.trimMargin(), 14) {
          bindString(0, slug)
          bindLong(1, id)
          bindString(2, title)
          bindString(3, author)
          bindString(4, feed)
          bindLong(5, collectionId)
          bindLong(6, artistId)
          bindString(7, thumbnail)
          bindString(8, imageUrl)
          bindLong(9, trackCount)
          bindString(10, primaryGenreName)
          bindString(11, summary)
          bindString(12, categories)
          bindString(13, feedimage)
        }.await()
    notifyQueries(-1_543_143_946) { emit ->
      emit("dbpodcast")
    }
  }

  public suspend fun insertEpisode(
    episodeSlug: String,
    title: String,
    author: String,
    created: Long,
    description: String?,
    image: String?,
    media: String,
    mediaType: String,
    fileSize: Long?,
    duration: Long?,
    parentFeed: String,
    parent_slug: String,
  ) {
    driver.execute(1_612_953_837, """
        |INSERT OR REPLACE INTO dbepisode (
        |    "episodeSlug",
        |    title,
        |    author,
        |    created,
        |    description,
        |    image,
        |    media,
        |    "mediaType",
        |    "fileSize",
        |    duration,
        |    "parentFeed",
        |    parent_slug
        |) VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)
        """.trimMargin(), 12) {
          bindString(0, episodeSlug)
          bindString(1, title)
          bindString(2, author)
          bindLong(3, created)
          bindString(4, description)
          bindString(5, image)
          bindString(6, media)
          bindString(7, mediaType)
          bindLong(8, fileSize)
          bindLong(9, duration)
          bindString(10, parentFeed)
          bindString(11, parent_slug)
        }.await()
    notifyQueries(1_612_953_837) { emit ->
      emit("dbepisode")
    }
  }

  private inner class SelectPodcastQuery<out T : Any>(
    public val slug: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("dbpodcast", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("dbpodcast", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(-308_180_397, """
    |SELECT dbpodcast.slug, dbpodcast.id, dbpodcast.title, dbpodcast.author, dbpodcast.feed, dbpodcast."collectionId", dbpodcast."artistId", dbpodcast.thumbnail, dbpodcast."imageUrl", dbpodcast."trackCount", dbpodcast."primaryGenreName", dbpodcast.summary, dbpodcast.categories, dbpodcast.feedimage
    |FROM dbpodcast
    |WHERE dbpodcast.slug = ?
    """.trimMargin(), mapper, 1) {
      bindString(0, slug)
    }

    override fun toString(): String = "podcast.sq:selectPodcast"
  }

  private inner class SelectPodcastEpisodesQuery<out T : Any>(
    public val parent_slug: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("dbepisode", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("dbepisode", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(-1_865_945_781, """
    |SELECT dbepisode."episodeSlug", dbepisode.title, dbepisode.author, dbepisode.created, dbepisode.description, dbepisode.image, dbepisode.media, dbepisode."mediaType", dbepisode."fileSize", dbepisode.duration, dbepisode."parentFeed", dbepisode.parent_slug
    |FROM dbepisode
    |WHERE dbepisode.parent_slug = ?
    |ORDER BY created DESC
    """.trimMargin(), mapper, 1) {
      bindString(0, parent_slug)
    }

    override fun toString(): String = "podcast.sq:selectPodcastEpisodes"
  }

  private inner class SelectEpisodeQuery<out T : Any>(
    public val episodeSlug: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("dbepisode", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("dbepisode", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(-1_447_049_910, """
    |SELECT dbepisode."episodeSlug", dbepisode.title, dbepisode.author, dbepisode.created, dbepisode.description, dbepisode.image, dbepisode.media, dbepisode."mediaType", dbepisode."fileSize", dbepisode.duration, dbepisode."parentFeed", dbepisode.parent_slug
    |FROM dbepisode
    |WHERE dbepisode.episodeSlug = ?
    """.trimMargin(), mapper, 1) {
      bindString(0, episodeSlug)
    }

    override fun toString(): String = "podcast.sq:selectEpisode"
  }

  private inner class SelectListEpisodesQuery<out T : Any>(
    public val parent_slug: Collection<String>,
    public val `value`: Long,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("dbepisode", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("dbepisode", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> {
      val parent_slugIndexes = createArguments(count = parent_slug.size)
      return driver.executeQuery(null, """
          |SELECT dbepisode."episodeSlug", dbepisode.title, dbepisode.author, dbepisode.created, dbepisode.description, dbepisode.image, dbepisode.media, dbepisode."mediaType", dbepisode."fileSize", dbepisode.duration, dbepisode."parentFeed", dbepisode.parent_slug
          |FROM dbepisode
          |WHERE parent_slug IN $parent_slugIndexes
          |ORDER BY created DESC
          |LIMIT ?
          """.trimMargin(), mapper, 1 + parent_slug.size) {
            parent_slug.forEachIndexed { index, parent_slug_ ->
              bindString(index, parent_slug_)
            }
            bindLong(parent_slug.size, value)
          }
    }

    override fun toString(): String = "podcast.sq:selectListEpisodes"
  }
}
