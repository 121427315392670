package studio.goodegg.capsule.db

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import kotlin.Any
import kotlin.Boolean
import kotlin.String

public class SubscriptionQueries(
  driver: SqlDriver,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> selectAll(mapper: (
    slug: String,
    feedurl: String,
    title: String,
    artist: String,
    image: String?,
    notify: Boolean,
    collection: String?,
    autodownload: Boolean,
  ) -> T): Query<T> = Query(-1_273_474_211, arrayOf("subscription"), driver, "subscription.sq",
      "selectAll",
      "SELECT subscription.slug, subscription.feedurl, subscription.title, subscription.artist, subscription.image, subscription.notify, subscription.collection, subscription.autodownload FROM subscription") {
      cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4),
      cursor.getBoolean(5)!!,
      cursor.getString(6),
      cursor.getBoolean(7)!!
    )
  }

  public fun selectAll(): Query<Subscription> = selectAll { slug, feedurl, title, artist, image,
      notify, collection, autodownload ->
    Subscription(
      slug,
      feedurl,
      title,
      artist,
      image,
      notify,
      collection,
      autodownload
    )
  }

  public fun <T : Any> selectSubscription(slug: String, mapper: (
    slug: String,
    feedurl: String,
    title: String,
    artist: String,
    image: String?,
    notify: Boolean,
    collection: String?,
    autodownload: Boolean,
  ) -> T): Query<T> = SelectSubscriptionQuery(slug) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getString(3)!!,
      cursor.getString(4),
      cursor.getBoolean(5)!!,
      cursor.getString(6),
      cursor.getBoolean(7)!!
    )
  }

  public fun selectSubscription(slug: String): Query<Subscription> = selectSubscription(slug) {
      slug_, feedurl, title, artist, image, notify, collection, autodownload ->
    Subscription(
      slug_,
      feedurl,
      title,
      artist,
      image,
      notify,
      collection,
      autodownload
    )
  }

  public suspend fun insert(
    slug: String,
    feedurl: String,
    title: String,
    artist: String,
    image: String?,
    notify: Boolean,
    collection: String?,
    autodownload: Boolean,
  ) {
    driver.execute(325_249_377, """
        |INSERT INTO subscription (slug, feedurl, title, artist, image, notify, collection, autodownload)
        |VALUES (?, ?, ?, ?, ?, ?, ?, ?)
        """.trimMargin(), 8) {
          bindString(0, slug)
          bindString(1, feedurl)
          bindString(2, title)
          bindString(3, artist)
          bindString(4, image)
          bindBoolean(5, notify)
          bindString(6, collection)
          bindBoolean(7, autodownload)
        }.await()
    notifyQueries(325_249_377) { emit ->
      emit("subscription")
    }
  }

  public suspend fun delete(slug: String) {
    driver.execute(173_583_443, """DELETE FROM subscription WHERE slug = ?""", 1) {
          bindString(0, slug)
        }.await()
    notifyQueries(173_583_443) { emit ->
      emit("subscription")
    }
  }

  private inner class SelectSubscriptionQuery<out T : Any>(
    public val slug: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("subscription", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("subscription", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(1_070_772_961,
        """SELECT subscription.slug, subscription.feedurl, subscription.title, subscription.artist, subscription.image, subscription.notify, subscription.collection, subscription.autodownload FROM subscription WHERE slug = ?""",
        mapper, 1) {
      bindString(0, slug)
    }

    override fun toString(): String = "subscription.sq:selectSubscription"
  }
}
