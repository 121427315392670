package io.daio.pancake.bottomsheet

import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.LayoutScopeMarker
import androidx.compose.foundation.layout.padding
import androidx.compose.material.ExperimentalMaterialApi
import androidx.compose.material.Icon
import androidx.compose.material.ModalBottomSheetState
import androidx.compose.material.ModalBottomSheetValue
import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.semantics.Role
import androidx.compose.ui.semantics.role
import androidx.compose.ui.semantics.semantics
import androidx.compose.ui.unit.Density
import compose.icons.feathericons.XCircle
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.annotations.InternalPancakeApi
import io.daio.pancake.foundations.text.BodyText
import io.daio.pancake.icons.Icons
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.Stack
import io.daio.pancake.layout.Tier
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.launch
import kotlinx.coroutines.sync.Mutex
import kotlinx.coroutines.sync.withLock

@Composable
fun rememberBottomSheetState(): BottomSheetState {
    val density = LocalDensity.current
    val scope = rememberCoroutineScope()
    return remember(density) { createBottomSheetState(density, scope) }
}

@OptIn(ExperimentalMaterialApi::class)
fun createBottomSheetState(
    density: Density,
    scope: CoroutineScope = CoroutineScope(Dispatchers.Main + SupervisorJob()),
): BottomSheetState {
    return BottomSheetState(
        ModalBottomSheetState(
            ModalBottomSheetValue.Hidden,
            isSkipHalfExpanded = false,
            density = density,
        ),
        scope = scope,
    )
}

@LayoutScopeMarker
@Immutable
interface BottomSheetScope {
    fun dismiss()

    fun setContent(
        title: String? = null,
        content: @Composable BottomSheetScope.() -> Unit,
    )
}

@OptIn(ExperimentalMaterialApi::class, InternalPancakeApi::class)
class BottomSheetState internal constructor(
    // Todo move away from material.
    @InternalPancakeApi
    val sheetState: ModalBottomSheetState,
    private val scope: CoroutineScope,
) {
    @InternalPancakeApi
    var currentContent by mutableStateOf<(@Composable () -> Unit)?>(null)
        private set

    private val bottomSheetScope =
        object : BottomSheetScope {
            override fun dismiss() {
                this@BottomSheetState.dismiss()
            }

            override fun setContent(
                title: String?,
                content: @Composable BottomSheetScope.() -> Unit,
            ) {
                this@BottomSheetState.setContent(title, content)
            }
        }

    private val mutex = Mutex()

    fun show(
        title: String? = null,
        content: @Composable BottomSheetScope.() -> Unit,
    ) {
        setContent(title, content)
        open()
    }

    fun open() {
        scope.launch {
            mutex.withLock {
                if (currentContent != null) {
                    sheetState.show()
                }
            }
        }
    }

    fun setContent(
        title: String? = null,
        content: @Composable BottomSheetScope.() -> Unit,
    ) {
        currentContent = {
            LaunchedEffect(sheetState.isVisible) {
                if (sheetState.currentValue == ModalBottomSheetValue.Hidden) {
                    dismiss()
                }
            }

            Stack(spaceBetween = LayoutGap.None) {
                title?.takeIf { it.isNotBlank() }?.let {
                    BottomSheetHeading(
                        it,
                        onDismiss = {
                            dismiss()
                        },
                    )
                }
                content.invoke(bottomSheetScope)
            }
        }
    }

    fun dismiss() {
        scope.launch {
            mutex.withLock {
                sheetState.hide()
            }
            currentContent = null
        }
    }
}

@Composable
private fun BottomSheetHeading(
    title: String,
    onDismiss: () -> Unit,
    modifier: Modifier = Modifier,
) {
    Tier(
        modifier =
            modifier.padding(
                horizontal = Theme.dimens.size_m,
                vertical = Theme.dimens.size_l,
            ),
    ) {
        BodyText(title, modifier = Modifier.weight(1f))
        Icon(
            imageVector = Icons.XCircle,
            contentDescription = null,
            modifier =
                Modifier
                    .semantics { role = Role.Button }
                    .clickable(onClick = onDismiss),
        )
    }
}
