package studio.goodegg.capsule.details.podcast

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.produceState
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import com.slack.circuit.retained.collectAsRetainedState
import com.slack.circuit.retained.rememberRetained
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.Navigator
import com.slack.circuit.runtime.presenter.Presenter
import com.slack.circuit.runtime.screen.Screen
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.PodcastSubscription
import studio.goodegg.capsule.PlayState
import studio.goodegg.capsule.domain.playback.ObserveAllPlayedInteractor
import studio.goodegg.capsule.domain.playback.ObserveNowPlayingInteractor
import studio.goodegg.capsule.domain.playback.PauseInteractor
import studio.goodegg.capsule.domain.playback.PlayInteractor
import studio.goodegg.capsule.domain.playback.ResumePlaybackInteractor
import studio.goodegg.capsule.domain.podcast.ObservePodcastInteractor
import studio.goodegg.capsule.domain.subscription.AddSubscriptionInteractor
import studio.goodegg.capsule.domain.subscription.DeleteSubscriptionInteractor
import studio.goodegg.capsule.domain.subscription.ObserveSubscriptionInteractor
import studio.goodegg.capsule.navigation.PodcastDetailsScreen

@Inject
class PodcastDetailsPresenterFactory(
    private val observePodcastInteractor: ObservePodcastInteractor,
    private val playInteractor: PlayInteractor,
    private val observeNowPlaying: ObserveNowPlayingInteractor,
    private val pauseInteractor: PauseInteractor,
    private val resumePlaybackInteractor: ResumePlaybackInteractor,
    private val observeSubscriptionInteractor: ObserveSubscriptionInteractor,
    private val deleteSubscriptionInteractor: DeleteSubscriptionInteractor,
    private val addSubscriptionInteractor: AddSubscriptionInteractor,
    private val observeAllPlayedInteractor: ObserveAllPlayedInteractor,
) : Presenter.Factory {
    override fun create(
        screen: Screen,
        navigator: Navigator,
        context: CircuitContext,
    ): Presenter<*>? {
        return when (screen) {
            is PodcastDetailsScreen -> PodcastDetailsPresenter(
                screen.slug,
                navigator,
                observePodcastInteractor,
                playInteractor,
                observeNowPlaying,
                pauseInteractor,
                resumePlaybackInteractor,
                observeSubscriptionInteractor,
                deleteSubscriptionInteractor,
                addSubscriptionInteractor,
                observeAllPlayedInteractor,
            )

            else -> null
        }
    }
}

class PodcastDetailsPresenter(
    private val slug: String,
    private val navigator: Navigator,
    private val observePodcastInteractor: ObservePodcastInteractor,
    private val playInteractor: PlayInteractor,
    private val observeNowPlaying: ObserveNowPlayingInteractor,
    private val pauseInteractor: PauseInteractor,
    private val resumePlaybackInteractor: ResumePlaybackInteractor,
    private val observeSubscriptionInteractor: ObserveSubscriptionInteractor,
    private val deleteSubscriptionInteractor: DeleteSubscriptionInteractor,
    private val addSubscriptionInteractor: AddSubscriptionInteractor,
    private val observeAllPlayedInteractor: ObserveAllPlayedInteractor,
) : Presenter<PodcastDetailsUiState> {
    @Composable
    override fun present(): PodcastDetailsUiState {
        val scope = rememberCoroutineScope()

        val subscribed by rememberRetained { observeSubscriptionInteractor(slug) }
            .collectAsRetainedState(false)

        val played by rememberRetained {
            observeAllPlayedInteractor().map { playedItems ->
                playedItems.map { it.slug }.toSet()
            }
        }.collectAsRetainedState(emptySet())

        val podcast by rememberRetained { observePodcastInteractor.invoke(slug) }
            .collectAsState(null)

        val loading by produceState(true, podcast) {
            delay(100)
            value = podcast == null
        }

        val nowPlaying by remember { observeNowPlaying() }.collectAsState(null)

        fun eventSink(event: PodcastDetailsUiEvent) {
            when (event) {
                is PodcastDetailsUiEvent.OpenEpisodeDetails -> TODO()
                is PodcastDetailsUiEvent.PlayEpisode -> {
                    if (nowPlaying?.episode?.episodeSlug == event.episode.episodeSlug) {
                        when (nowPlaying?.playState) {
                            PlayState.Buffering, PlayState.Playing -> pauseInteractor()
                            PlayState.Paused -> resumePlaybackInteractor()
                            else -> Unit
                        }
                    } else {
                        playInteractor(event.episode, 0)
                    }
                }

                PodcastDetailsUiEvent.Subscribe -> {
                    scope.launch {
                        if (subscribed) {
                            deleteSubscriptionInteractor(slug)
                        } else {
                            podcast?.let {
                                addSubscriptionInteractor(
                                    PodcastSubscription(
                                        slug = slug,
                                        feedUrl = it.feed,
                                        title = it.title,
                                        artist = it.author,
                                        image = it.imageUrl,
                                        notify = true,
                                        collection = it.collectionId.toString(),
                                        autoDownload = false,
                                    ),
                                )
                            }
                        }
                    }
                }

                is PodcastDetailsUiEvent.DownloadEpisode -> {

                }

                is PodcastDetailsUiEvent.ShareEpisode -> {

                }
            }
        }

        return PodcastDetailsUiState(
            podcast = podcast,
            isSubscribed = subscribed,
            loading = loading,
            playedEpisodesSlugs = played,
            nowPlaying = nowPlaying,
            eventSink = ::eventSink,
        )
    }
}
