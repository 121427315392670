package io.daio.pancake.components.snackbar

import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.padding
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyText
import io.daio.pancake.layout.Surface
import io.daio.pancake.layout.Tier

@Composable
fun Snackbar(
    value: String,
    modifier: Modifier = Modifier,
) {
    Surface(
        color = Theme.colors.surfaceInteractive,
        shape = Theme.shapes.large,
        modifier = modifier,
    ) {
        Tier(
            horizontalAlignment = Alignment.Start,
            modifier =
                Modifier.heightIn(min = 56.dp)
                    .fillMaxWidth()
                    .padding(Theme.dimens.size_s),
        ) {
            BodyText(value, textColor = Theme.colors.onSurfaceInteractive)
        }
    }
}
