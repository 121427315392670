package studio.goodegg.capsule.db

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import kotlin.Any
import kotlin.Long
import kotlin.String

public class PlayedQueries(
  driver: SqlDriver,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> selectCurrentInProgress(mapper: (
    slug: String,
    duration: Long,
    current_position: Long,
    last_played: Long,
    played_count: Long,
  ) -> T): Query<T> = Query(-2_066_378_671, arrayOf("played"), driver, "played.sq",
      "selectCurrentInProgress", """
  |SELECT played.slug, played.duration, played.current_position, played.last_played, played.played_count
  |FROM played
  |WHERE current_position < duration
  |ORDER BY last_played DESC
  |LIMIT 1
  """.trimMargin()) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)!!,
      cursor.getLong(2)!!,
      cursor.getLong(3)!!,
      cursor.getLong(4)!!
    )
  }

  public fun selectCurrentInProgress(): Query<Played> = selectCurrentInProgress { slug, duration,
      current_position, last_played, played_count ->
    Played(
      slug,
      duration,
      current_position,
      last_played,
      played_count
    )
  }

  public fun <T : Any> selectAll(mapper: (
    slug: String,
    duration: Long,
    current_position: Long,
    last_played: Long,
    played_count: Long,
  ) -> T): Query<T> = Query(-1_859_979_353, arrayOf("played"), driver, "played.sq", "selectAll", """
  |SELECT played.slug, played.duration, played.current_position, played.last_played, played.played_count
  |FROM played
  """.trimMargin()) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)!!,
      cursor.getLong(2)!!,
      cursor.getLong(3)!!,
      cursor.getLong(4)!!
    )
  }

  public fun selectAll(): Query<Played> = selectAll { slug, duration, current_position, last_played,
      played_count ->
    Played(
      slug,
      duration,
      current_position,
      last_played,
      played_count
    )
  }

  public fun <T : Any> selectAllInProgress(mapper: (
    slug: String,
    duration: Long,
    current_position: Long,
    last_played: Long,
    played_count: Long,
  ) -> T): Query<T> = Query(-19_731_079, arrayOf("played"), driver, "played.sq",
      "selectAllInProgress", """
  |SELECT played.slug, played.duration, played.current_position, played.last_played, played.played_count
  |FROM played
  |WHERE current_position < duration
  |ORDER BY last_played DESC
  """.trimMargin()) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)!!,
      cursor.getLong(2)!!,
      cursor.getLong(3)!!,
      cursor.getLong(4)!!
    )
  }

  public fun selectAllInProgress(): Query<Played> = selectAllInProgress { slug, duration,
      current_position, last_played, played_count ->
    Played(
      slug,
      duration,
      current_position,
      last_played,
      played_count
    )
  }

  public fun <T : Any> selectBySlug(slug: String, mapper: (
    slug: String,
    duration: Long,
    current_position: Long,
    last_played: Long,
    played_count: Long,
  ) -> T): Query<T> = SelectBySlugQuery(slug) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)!!,
      cursor.getLong(2)!!,
      cursor.getLong(3)!!,
      cursor.getLong(4)!!
    )
  }

  public fun selectBySlug(slug: String): Query<Played> = selectBySlug(slug) { slug_, duration,
      current_position, last_played, played_count ->
    Played(
      slug_,
      duration,
      current_position,
      last_played,
      played_count
    )
  }

  public fun <T : Any> selectAllPlayed(mapper: (
    slug: String,
    duration: Long,
    current_position: Long,
    last_played: Long,
    played_count: Long,
  ) -> T): Query<T> = Query(-475_929_254, arrayOf("played"), driver, "played.sq", "selectAllPlayed",
      """
  |SELECT played.slug, played.duration, played.current_position, played.last_played, played.played_count
  |FROM played
  |WHERE played_count >= 1
  """.trimMargin()) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)!!,
      cursor.getLong(2)!!,
      cursor.getLong(3)!!,
      cursor.getLong(4)!!
    )
  }

  public fun selectAllPlayed(): Query<Played> = selectAllPlayed { slug, duration, current_position,
      last_played, played_count ->
    Played(
      slug,
      duration,
      current_position,
      last_played,
      played_count
    )
  }

  public suspend fun insert(
    slug: String,
    duration: Long,
    current_position: Long,
    last_played: Long,
    played_count: Long,
  ) {
    driver.execute(-199_116_457, """
        |INSERT OR REPLACE INTO played (slug, duration, current_position, last_played, played_count)
        |VALUES (?, ?, ?, ?, ?)
        """.trimMargin(), 5) {
          bindString(0, slug)
          bindLong(1, duration)
          bindLong(2, current_position)
          bindLong(3, last_played)
          bindLong(4, played_count)
        }.await()
    notifyQueries(-199_116_457) { emit ->
      emit("played")
    }
  }

  private inner class SelectBySlugQuery<out T : Any>(
    public val slug: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("played", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("played", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(-1_231_821_860, """
    |SELECT played.slug, played.duration, played.current_position, played.last_played, played.played_count
    |FROM played
    |WHERE slug = ?
    """.trimMargin(), mapper, 1) {
      bindString(0, slug)
    }

    override fun toString(): String = "played.sq:selectBySlug"
  }
}
