package studio.goodegg.capsule.resume

import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.lazy.items
import androidx.compose.material3.HorizontalDivider
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.screen.Screen
import com.slack.circuit.runtime.ui.Ui
import com.slack.circuit.runtime.ui.ui
import compose.icons.FeatherIcons
import compose.icons.feathericons.CheckCircle
import compose.icons.feathericons.Pause
import compose.icons.feathericons.Play
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyText
import io.daio.pancake.foundations.text.BodyTextSmall
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.LazyStack
import io.daio.pancake.layout.Stack
import io.daio.pancake.layout.Tier
import studio.goodegg.capsule.PlayState
import studio.goodegg.capsule.EpisodeAndPlayState
import studio.goodegg.capsule.domain.playback.EpisodeAndPosition
import studio.goodegg.capsule.navigation.ResumeScreen
import studio.goodegg.capsule.ui.components.ArtworkTile
import studio.goodegg.capsule.ui.components.IconTextButton
import studio.goodegg.capsule.ui.components.OutlineIconButton

class ResumeScreenUiFactory : Ui.Factory {
    override fun create(
        screen: Screen,
        context: CircuitContext,
    ): Ui<*>? {
        return when (screen) {
            is ResumeScreen -> resume()
            else -> null
        }
    }
}

private fun resume() =
    ui<ResumeUiState> { state, modifier ->
        Resume(
            state,
            modifier,
        )
    }

@OptIn(ExperimentalFoundationApi::class)
@Composable
fun Resume(
    state: ResumeUiState,
    modifier: Modifier = Modifier,
) {
    Stack(modifier = modifier) {
        BodyText("In Progress")
        LazyStack {
            items(
                state.items,
                key = { it.episode.episodeSlug },
            ) {
                Stack(modifier = Modifier.animateItemPlacement()) {
                    ResumeItem(
                        episodeAndPosition = it,
                        nowPlaying = state.nowPlaying,
                        onPlay = {
                            state.eventSink(ResumeUiEvent.Resume(it))
                        },
                        onMarkAsPlayed = {
                            state.eventSink(ResumeUiEvent.MarkAsPlayed(it.episode))
                        },
                    )
                    HorizontalDivider(color = Theme.colors.onSurfaceBackground.copy(alpha = .2f))
                }
            }
        }
    }
}

@Composable
private fun ResumeItem(
    episodeAndPosition: EpisodeAndPosition,
    nowPlaying: EpisodeAndPlayState?,
    onPlay: () -> Unit,
    onMarkAsPlayed: () -> Unit,
    modifier: Modifier = Modifier,
) {
    Stack(
        modifier = modifier
            .wrapContentHeight()
            .fillMaxWidth(),
        spaceBetween = LayoutGap.Small,
    ) {
        Tier(verticalAlignment = Alignment.Top) {
            ArtworkTile(
                size = 68.dp,
                imageUrl = episodeAndPosition.episode.image,
            )
            Stack(spaceBetween = LayoutGap.Medium) {
                BodyTextSmall(
                    episodeAndPosition.episode.title,
                    maxLines = 3,
                    overflow = TextOverflow.Ellipsis,
                )

                Tier(spaceBetween = LayoutGap.Tiny) {
                    val isNowPlaying =
                        episodeAndPosition.episode.episodeSlug == nowPlaying?.episode?.episodeSlug
                    val isPaused = isNowPlaying && nowPlaying?.playState == PlayState.Paused

                    IconTextButton(
                        icon = if (isPaused || !isNowPlaying) FeatherIcons.Play else FeatherIcons.Pause,
                        text = "${episodeAndPosition.formatTimeRemaining()} left",
                        onClick = onPlay,
                    )
                    OutlineIconButton(
                        icon = FeatherIcons.CheckCircle,
                        onClick = onMarkAsPlayed,
                    )
                }
            }
        }
    }
}
