package studio.goodegg.capsule.repositories

import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.TrendingRow
import studio.goodegg.capsule.api.clients.DiscoveryClient
import studio.goodegg.capsule.utils.Failed
import studio.goodegg.capsule.utils.Success

interface TrendingRepository {
    suspend fun getTrending(locale: String): List<TrendingRow>
}

@Inject
internal class TrendingRepositoryImpl(private val discoveryClient: DiscoveryClient) : TrendingRepository {
    override suspend fun getTrending(locale: String): List<TrendingRow> {
        return when (val result = discoveryClient.getTrending(locale)) {
            is Failed -> emptyList()
            is Success -> result.data.trending
        }
    }
}
