package studio.goodegg.capsule.domain.trending

import me.tatarka.inject.annotations.Component
import me.tatarka.inject.annotations.Provides
import studio.goodegg.capsule.repositories.TrendingRepository

@Component
interface TrendingDomainComponent {
    @Provides
    fun providesGetTrendingInteractor(trendingRepository: TrendingRepository): GetTrendingInteractor =
        GetTrendingInteractor { locale -> trendingRepository.getTrending(locale) }
}
