package io.daio.pancake.components.search

import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.Icon
import androidx.compose.material3.TextField
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.unit.dp
import compose.icons.feathericons.XCircle
import io.daio.pancake.components.text.TextAreaColors
import io.daio.pancake.components.text.TextAreaDefaults
import io.daio.pancake.components.text.toTextFieldColors
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyText
import io.daio.pancake.icons.Icons
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.Tier

@Composable
fun SearchBar(
    value: String,
    onValueChange: (String) -> Unit,
    modifier: Modifier = Modifier,
    onTrailingIconClicked: (() -> Unit)? = null,
    onEnter: (() -> Unit)? = null,
    interactionSource: MutableInteractionSource = remember { MutableInteractionSource() },
    placeholder: @Composable () -> Unit = {
        BodyText("Search", textColor = Theme.colors.onSurfaceBackground.copy(.4f))
    },
    colors: TextAreaColors = TextAreaDefaults.colors(),
) {
    Tier(
        spaceBetween = LayoutGap.Small,
        modifier =
            modifier.border(1.dp, Theme.colors.onSurfaceBackground, Theme.shapes.circle)
                .height(54.dp)
                .padding(horizontal = Theme.dimens.size_xs),
    ) {
        TextField(
            value = value,
            interactionSource = interactionSource,
            onValueChange = onValueChange,
            maxLines = 1,
            keyboardActions =
                KeyboardActions {
                    onEnter?.invoke()
                },
            keyboardOptions =
                KeyboardOptions.Default.copy(
                    imeAction = ImeAction.Search,
                ),
            modifier = Modifier.fillMaxWidth(),
            colors = colors.toTextFieldColors(),
            textStyle = Theme.typography.bodyDefault,
            singleLine = true,
            trailingIcon = {
                Icon(
                    imageVector = Icons.XCircle,
                    contentDescription = null,
                    modifier = Modifier.clickable { onTrailingIconClicked?.invoke() },
                )
            },
            placeholder = placeholder,
        )
    }
}
