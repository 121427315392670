package studio.goodegg.capsule.core.playback

import io.daio.bass.BassContext
import io.daio.bass.BassPlayer
import io.daio.bass.bassPlayer
import me.tatarka.inject.annotations.Provides
import studio.goodegg.capsule.common.di.ApplicationScope

actual interface PlatformPlayerComponent {
    @Provides
    @ApplicationScope
    fun providesPlayer(): Player = PlayerImpl(BassContext.INSTANCE.bassPlayer)
}