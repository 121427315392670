package studio.goodegg.capsule.core.playback

import io.daio.bass.BassPlayer
import io.daio.bass.MediaItem
import io.daio.bass.PlayerState
import io.daio.bass.playbackProgressChanges
import io.daio.bass.playbackStateChanges
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.Episode
import studio.goodegg.capsule.PlayState

interface Player {
    fun play(episode: Episode, position: Long)

    fun stop()

    fun pause()

    fun resume()

    val currentItem: Episode?
    val currentPosition: Long

    fun playerProgress(): Flow<PlayerProgress>

    fun playbackState(): Flow<PlayState>
}

@Inject
internal class PlayerImpl(private val bassPlayer: BassPlayer) : Player {

    override val currentItem: Episode?
        get() = bassPlayer.currentItem?.asEpisode()

    override val currentPosition: Long
        get() = bassPlayer.currentPosition

    override fun play(episode: Episode, position: Long) {
        bassPlayer.play(episode.asBassMediaItem())
        // TODO this needs to be part of Bass to start at offset.
        bassPlayer.seekByDelta(position)
    }

    override fun stop() {
        bassPlayer.stop()
    }

    override fun pause() {
        bassPlayer.pause()
    }

    override fun resume() {
        bassPlayer.resume()
    }

    override fun playerProgress(): Flow<PlayerProgress> {
        return bassPlayer.playbackProgressChanges().map {
            PlayerProgress(it.duration, it.progress, it.percentage)
        }
    }

    override fun playbackState(): Flow<PlayState> {
        return bassPlayer.playbackStateChanges().map {
            when (it) {
                PlayerState.Buffering -> PlayState.Buffering
                PlayerState.Error -> PlayState.Error
                PlayerState.Idle -> PlayState.Idle
                PlayerState.Paused -> PlayState.Paused
                PlayerState.Playing -> PlayState.Playing
                PlayerState.Stopped -> PlayState.Stopped
            }
        }
    }
}

fun Episode.asBassMediaItem(): MediaItem = MediaItem(
    url = media,
    title = title,
    artist = author,
    artworkUrl = image,
    length = duration,
    extras = mapOf(
        "created" to created.toString(),
        "filesize" to fileSize.toString(),
        "slug" to episodeSlug,
        "parentslug" to parentSlug,
        "parentfeed" to parentFeed,
        "mediatype" to mediaType,
        "description" to description,
        "episodetype" to episodeType,
    ),
)

fun MediaItem.asEpisode(): Episode = Episode(
    media = url,
    title = title!!,
    author = artist!!,
    image = artworkUrl.orEmpty(),
    duration = length,
    episodeSlug = extras["slug"].orEmpty(),
    parentSlug = extras["parentslug"],
    fileSize = extras["filesize"]!!.toInt(),
    mediaType = extras["mediatype"]!!,
    created = extras["created"]!!.toLong(),
    description = extras["description"],
    parentFeed = extras["parentfeed"].orEmpty(),
    episodeType = extras["episodetype"].orEmpty(),
)

data class PlayerProgress(
    val duration: Long,
    val progress: Long,
    val percentage: Float,
)

val ZeroProgress = PlayerProgress(0, 0, 0f)
