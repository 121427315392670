package studio.goodegg.capsule.details.podcast

import com.slack.circuit.runtime.CircuitUiEvent
import studio.goodegg.capsule.Episode

sealed class PodcastDetailsUiEvent : CircuitUiEvent {
    data class PlayEpisode(val episode: Episode) : PodcastDetailsUiEvent()
    data class DownloadEpisode(val episode: Episode) : PodcastDetailsUiEvent()
    data class ShareEpisode(val episode: Episode) : PodcastDetailsUiEvent()
    data class OpenEpisodeDetails(val episode: Episode) : PodcastDetailsUiEvent()
    data object Subscribe : PodcastDetailsUiEvent()
}
