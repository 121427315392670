package studio.goodegg.capsule.base.main

import me.tatarka.inject.`internal`.LazyMap
import me.tatarka.inject.`internal`.ScopedComponent

public fun JsAppComponent.Companion.create(): JsAppComponent = InjectJsAppComponent()

public class InjectJsAppComponent() : JsAppComponent(), ScopedComponent {
  override val _scoped: LazyMap = LazyMap()
}
