package studio.goodegg.capsule.domain.playback

import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.onStart
import me.tatarka.inject.annotations.Component
import me.tatarka.inject.annotations.Inject
import me.tatarka.inject.annotations.Provides
import studio.goodegg.capsule.EpisodeAndPlayState
import studio.goodegg.capsule.PlayState
import studio.goodegg.capsule.core.playback.Player
import studio.goodegg.capsule.core.playback.ZeroProgress
import studio.goodegg.capsule.repositories.PlayedItemsRepository
import studio.goodegg.capsule.repositories.PodcastRepository
import studio.goodegg.capsule.repositories.percentage

@Component
interface PlaybackDomainComponent {

    @Provides
    @Inject
    fun providesGetNowPlayingInteractor(
        playedItemsRepository: PlayedItemsRepository,
        player: Player,
    ): GetNowPlayingInteractor =
        GetNowPlayingInteractor {
            player.currentItem?.let {
                val progress = playedItemsRepository.getBySlug(it.episodeSlug)
                val percentage = progress?.percentage() ?: 0f

                EpisodeAndPosition(
                    episode = it,
                    currentPosition = progress?.currentPosition ?: 0,
                    progress = percentage,
                )
            }
        }

    @Provides
    @Inject
    fun providesObserveNowPlayingInteractor(
        playedItemsRepository: PlayedItemsRepository,
        player: Player,
    ): ObserveNowPlayingInteractor =
        ObserveNowPlayingInteractor {
            player.playbackState()
                .combine(
                    player.playerProgress().onStart { emit(ZeroProgress) },
                ) { playState, progress ->
                    when (playState) {
                        PlayState.Buffering, PlayState.Paused, PlayState.Playing -> {
                            player.currentItem?.let {
                                val playedItem = if (progress == ZeroProgress)
                                    playedItemsRepository.getBySlug(it.episodeSlug)
                                else
                                    null

                                val percentage = playedItem?.percentage() ?: progress.percentage

                                EpisodeAndPlayState(
                                    episode = it,
                                    currentPosition = playedItem?.currentPosition
                                        ?: progress.progress,
                                    progress = percentage,
                                    playState = playState,
                                )
                            }
                        }

                        else -> null
                    }
                }
        }

    @Provides
    @Inject
    fun providesObserveAllPlayedInteractor(
        playedItemsRepository: PlayedItemsRepository,
    ): ObserveAllPlayedInteractor = ObserveAllPlayedInteractor {
        playedItemsRepository.observeCompletePlayed()
    }

    @Provides
    @Inject
    fun providesPlayInteractor(player: Player): PlayInteractor =
        PlayInteractor { episode, position -> player.play(episode, position) }

    @Provides
    @Inject
    fun providesPauseInteractor(player: Player): PauseInteractor =
        PauseInteractor { player.pause() }

    @Provides
    @Inject
    fun providesStopInteractor(player: Player): StopInteractor =
        StopInteractor { player.stop() }

    @Provides
    @Inject
    fun providesResumeInteractor(player: Player): ResumePlaybackInteractor =
        ResumePlaybackInteractor { player.resume() }

    @Provides
    @Inject
    fun providesObservePlayStateInteractor(player: Player): ObservePlayStateInteractor =
        ObservePlayStateInteractor { player.playbackState() }

    @Provides
    @Inject
    fun providesObservePlayProgressInteractor(player: Player): ObservePlayProgressInteractor =
        ObservePlayProgressInteractor { player.playerProgress() }

    @Provides
    @Inject
    fun providesMarkAsPlayedInteractor(playedItemsRepository: PlayedItemsRepository): MarkAsPlayedInteractor =
        MarkAsPlayedInteractor { playedItemsRepository.markAsPlayed(it) }

    @Provides
    @Inject
    fun providesMarkAsUnPlayedInteractor(playedItemsRepository: PlayedItemsRepository): UnMarkAsPlayedInteractor =
        UnMarkAsPlayedInteractor { playedItemsRepository.unmarkAsPlayed(it) }

    @Provides
    @Inject
    fun providesObserveInProgressInteractor(
        playedItemsRepository: PlayedItemsRepository,
        podcastRepository: PodcastRepository,
    ): ObserveInProgressInteractor = ObserveInProgressInteractor {
        playedItemsRepository.observeInProgress().map {
            it.mapNotNull {
                val episode = podcastRepository.getEpisode(it.slug) ?: return@mapNotNull null
                EpisodeAndPosition(episode, it.currentPosition, it.percentage())
            }
        }
    }

    @Provides
    @Inject
    fun providesGetLastPlayedInteractor(
        playedItemsRepository: PlayedItemsRepository,
        podcastRepository: PodcastRepository,
        player: Player,
    ): GetLastPlayedInteractor =
        GetLastPlayedInteractor {
            player.currentItem?.let {
                val progress = playedItemsRepository.getBySlug(it.episodeSlug)
                val percentage = progress?.percentage() ?: 0f

                return@GetLastPlayedInteractor EpisodeAndPlayState(
                    episode = it,
                    currentPosition = progress?.currentPosition ?: 0,
                    progress = percentage,
                    playState = PlayState.Idle,
                )
            }

            val recent = playedItemsRepository.getMostRecentlyPlayed()
            if (recent != null) {
                val episode =
                    podcastRepository.getEpisode(recent.slug) ?: return@GetLastPlayedInteractor null

                EpisodeAndPlayState(
                    episode = episode,
                    currentPosition = recent.currentPosition,
                    progress = recent.percentage(),
                    playState = PlayState.Idle,
                )

            } else {
                null
            }
        }
}
