package studio.goodegg.capsule.trending

import com.slack.circuit.runtime.CircuitUiEvent
import studio.goodegg.capsule.PodcastResult

sealed class TrendingUiEvent : CircuitUiEvent {
    data class OpenPodcast(val slug: String) : TrendingUiEvent()

    data class AddToSubscriptions(val podcast: PodcastResult) : TrendingUiEvent()

    data object OpenSearch : TrendingUiEvent()
}
