package studio.goodegg.capsule.details.podcast

import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.lazy.items
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.minimumInteractiveComponentSize
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.unit.dp
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.screen.Screen
import com.slack.circuit.runtime.ui.Ui
import com.slack.circuit.runtime.ui.ui
import compose.icons.FeatherIcons
import compose.icons.feathericons.Bell
import compose.icons.feathericons.Bookmark
import compose.icons.feathericons.Plus
import compose.icons.feathericons.Share2
import io.daio.pancake.components.loading.FullScreenLoadingIndicator
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyTextSmall
import io.daio.pancake.foundations.text.TitleText
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.LazyStack
import io.daio.pancake.layout.Stack
import io.daio.pancake.layout.Tier
import studio.goodegg.capsule.navigation.PodcastDetailsScreen
import studio.goodegg.capsule.ui.components.ArtworkTile
import studio.goodegg.capsule.ui.components.EpisodeItem
import studio.goodegg.capsule.ui.components.IconTextButton

class PodcastDetailsScreenUiFactory : Ui.Factory {
    override fun create(
        screen: Screen,
        context: CircuitContext,
    ): Ui<*>? {
        return when (screen) {
            is PodcastDetailsScreen -> podcastDetails()
            else -> null
        }
    }
}

private fun podcastDetails() =
    ui<PodcastDetailsUiState> { state, modifier ->
        PodcastDetails(
            state,
            modifier,
        )
    }

@OptIn(ExperimentalFoundationApi::class)
@Composable
fun PodcastDetails(
    state: PodcastDetailsUiState,
    modifier: Modifier = Modifier,
) {
    Box(
        modifier = modifier.fillMaxSize()
            .padding(top = Theme.dimens.size_l),
    ) {
        if (state.loading)
            FullScreenLoadingIndicator()

        Stack(
            modifier = Modifier.fillMaxSize(),
            spaceBetween = LayoutGap.Large,
        ) {
            Stack(modifier = Modifier.fillMaxWidth()) {
                Tier {
                    ArtworkTile(
                        imageUrl = state.podcast?.imageUrl,
                        size = 120.dp,
                    )
                    TitleText(state.podcast?.title.orEmpty())
                }

                val actionButtonAlpha by animateFloatAsState(if (state.loading) 0f else 1f)
                Tier(
                    modifier = Modifier.align(Alignment.End)
                        .graphicsLayer {
                            alpha = actionButtonAlpha
                        },
                ) {
                    Icon(
                        modifier = Modifier.size(20.dp).minimumInteractiveComponentSize(),
                        imageVector = FeatherIcons.Bell,
                        contentDescription = "share",
                        tint = Theme.colors.onSurfaceBackground,
                    )
                    Icon(
                        modifier = Modifier.size(20.dp).minimumInteractiveComponentSize(),
                        imageVector = FeatherIcons.Share2,
                        contentDescription = "share",
                        tint = Theme.colors.onSurfaceBackground,
                    )
                    IconTextButton(
                        icon = if (state.isSubscribed) FeatherIcons.Bookmark else FeatherIcons.Plus,
                        text = if (state.isSubscribed) "Subscribed" else "Subscribe",
                        enabled = !state.loading,
                        onClick = {
                            state.eventSink(PodcastDetailsUiEvent.Subscribe)
                        },
                    )
                }
            }
            Stack {
                BodyTextSmall(state.podcast?.summary.orEmpty(), maxLines = 4)
            }
            LazyStack {
                items(
                    items = state.podcast?.episodes.orEmpty(),
                    key = { it.episodeSlug },
                ) {
                    Stack {
                        val played = remember(it.episodeSlug) {
                            state.playedEpisodesSlugs.contains(it.episodeSlug)
                        }

                        EpisodeItem(
                            modifier = Modifier.animateItemPlacement(),
                            played = played,
                            episode = it,
                            nowPlaying = state.nowPlaying,
                            onPlay = {
                                state.eventSink(PodcastDetailsUiEvent.PlayEpisode(it))
                            },
                            onShare = {
                                state.eventSink(PodcastDetailsUiEvent.ShareEpisode(it))
                            },
                            onDownload = {
                                state.eventSink(PodcastDetailsUiEvent.DownloadEpisode(it))
                            },
                        )
                        HorizontalDivider(color = Theme.colors.onSurfaceBackground.copy(alpha = .2f))
                    }
                }
            }
        }
    }
}
