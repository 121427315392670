package studio.goodegg.capsule.subscriptions

import androidx.compose.runtime.Immutable
import com.slack.circuit.runtime.CircuitUiState
import studio.goodegg.capsule.PodcastSubscription

@Immutable
data class SubscriptionsUiState(
    val subscriptions: List<PodcastSubscription>,
    val eventSink: (SubscriptionsUiEvent) -> Unit,
) : CircuitUiState
