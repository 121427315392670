package io.daio.bass

internal class BassPlayerOptions {
    private val options: MutableMap<String, Any> = mutableMapOf()

    fun get(key: String): Any? {
        return options[key]
    }

    fun put(
        key: String,
        value: Any,
    ) {
        options[key] = value
    }
}
