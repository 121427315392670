package studio.goodegg.capsule.listen

import androidx.compose.runtime.Composable
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.Navigator
import com.slack.circuit.runtime.presenter.Presenter
import com.slack.circuit.runtime.screen.Screen
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.navigation.ListenScreen
import studio.goodegg.capsule.navigation.ResumeScreen

@Inject
class ListenPresenterFactory : Presenter.Factory {
    override fun create(
        screen: Screen,
        navigator: Navigator,
        context: CircuitContext,
    ): Presenter<*>? {
        return when (screen) {
            is ListenScreen -> ListenPresenter(navigator = navigator)
            else -> null
        }
    }
}

class ListenPresenter(
    private val navigator: Navigator,
) : Presenter<ListenUiState> {
    @Composable
    override fun present(): ListenUiState {
        fun eventSink(event: ListenUiEvent) {
            when (event) {
                is ListenUiEvent.OpenInProgress -> navigator.goTo(ResumeScreen)
            }
        }

        return ListenUiState(
            eventSink = ::eventSink,
        )
    }
}
