package io.daio.bass

actual abstract class BassContext {
    companion object {
        val INSTANCE = object : BassContext() {}
    }
}

internal actual fun BassContext.playerFactory(): BassPlayer.Factory {
    return DefaultPlayerFactory()
}

internal actual fun createPlayer(
    context: BassContext,
    options: BassPlayerOptions,
): BassPlayer {
    return AudioJsBassPlayer()
}
