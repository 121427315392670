package io.daio.pancake.layout

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyGridScope
import androidx.compose.foundation.lazy.grid.LazyHorizontalGrid
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp

@Composable
fun LazyTierGrid(
    rows: Int,
    modifier: Modifier = Modifier,
    gap: LayoutGap = LayoutGap.Medium,
    contentPadding: PaddingValues = PaddingValues(0.dp),
    content: LazyGridScope.() -> Unit,
) {
    LazyHorizontalGrid(
        rows = GridCells.Fixed(rows),
        modifier = modifier,
        contentPadding = contentPadding,
        horizontalArrangement = Arrangement.spacedBy(gap.size),
        verticalArrangement = Arrangement.spacedBy(gap.size),
        content = content,
    )
}

@Composable
fun LazyStackGrid(
    columns: Int,
    modifier: Modifier = Modifier,
    gap: LayoutGap = LayoutGap.Medium,
    contentPadding: PaddingValues = PaddingValues(0.dp),
    content: LazyGridScope.() -> Unit,
) {
    LazyVerticalGrid(
        columns = GridCells.Fixed(columns),
        modifier = modifier,
        contentPadding = contentPadding,
        horizontalArrangement = Arrangement.spacedBy(gap.size),
        verticalArrangement = Arrangement.spacedBy(gap.size),
        content = content,
    )
}
