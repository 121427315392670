package studio.goodegg.capsule.api

import io.ktor.client.HttpClient
import me.tatarka.inject.annotations.Component
import me.tatarka.inject.annotations.Provides
import studio.goodegg.capsule.api.clients.DiscoveryClient
import studio.goodegg.capsule.api.clients.DiscoveryClientImpl
import studio.goodegg.capsule.api.clients.PodcastClient
import studio.goodegg.capsule.api.clients.PodcastClientImpl
import studio.goodegg.capsule.common.di.ApplicationScope

expect interface NetworkClientComponent

@Component
interface ApiComponent : NetworkClientComponent {
    @Provides
    @ApplicationScope
    fun providesDiscoveryClient(httpClient: HttpClient): DiscoveryClient =
        DiscoveryClientImpl(httpClient)

    @Provides
    @ApplicationScope
    fun providesPodcastClient(httpClient: HttpClient): PodcastClient = PodcastClientImpl(httpClient)
}
