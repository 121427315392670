package io.daio.pancake.components.tabs

import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.material3.LocalContentColor
import androidx.compose.material3.TabRow
import androidx.compose.material3.TabRowDefaults.SecondaryIndicator
import androidx.compose.material3.TabRowDefaults.tabIndicatorOffset
import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.semantics.Role
import androidx.compose.ui.semantics.role
import androidx.compose.ui.semantics.semantics
import androidx.compose.ui.text.style.TextAlign
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyText

@Immutable
data class TabBarItem(
    val title: String,
)

@Composable
fun TabBar(
    items: List<TabBarItem>,
    onClick: (TabBarItem) -> Unit,
    modifier: Modifier = Modifier,
    initialItem: Int = 0,
) {
    val selectedItem = remember { mutableStateOf(initialItem) }

    TabRow(
        selectedItem.value,
        modifier = Modifier.wrapContentHeight().then(modifier),
        containerColor = Theme.colors.surfaceBackground,
        contentColor = Theme.colors.onSurfaceBackground,
        indicator = { tabPositions ->
            SecondaryIndicator(
                Modifier.tabIndicatorOffset(tabPositions[selectedItem.value]),
                color = LocalContentColor.current,
            )
        },
        divider = {},
    ) {
        items.forEachIndexed { i, it ->
            BodyText(
                text = it.title,
                textAlign = TextAlign.Center,
                modifier =
                    Modifier
                        .height(Theme.dimens.size_xl)
                        .semantics { role = Role.Tab }
                        .clickable {
                            onClick(it)
                            selectedItem.value = i
                        },
            )
        }
    }
}
