package studio.goodegg.capsule.player

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.rememberCoroutineScope
import com.slack.circuit.retained.collectAsRetainedState
import com.slack.circuit.retained.rememberRetained
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.Navigator
import com.slack.circuit.runtime.presenter.Presenter
import com.slack.circuit.runtime.screen.Screen
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.PlayState
import studio.goodegg.capsule.domain.playback.GetLastPlayedInteractor
import studio.goodegg.capsule.domain.playback.ObserveNowPlayingInteractor
import studio.goodegg.capsule.domain.playback.ObservePlayStateInteractor
import studio.goodegg.capsule.domain.playback.PauseInteractor
import studio.goodegg.capsule.domain.playback.PlayInteractor
import studio.goodegg.capsule.domain.playback.ResumePlaybackInteractor
import studio.goodegg.capsule.domain.playback.StopInteractor
import studio.goodegg.capsule.navigation.PlayerScreen

@Inject
class PlayerPresenterFactory(
    private val playInteractor: PlayInteractor,
    private val pauseInteractor: PauseInteractor,
    private val stopInteractor: StopInteractor,
    private val resumeInteractor: ResumePlaybackInteractor,
    private val nowPlayingInteractor: ObserveNowPlayingInteractor,
    private val observePlayStateInteractor: ObservePlayStateInteractor,
    private val lastPlayedInteractor: GetLastPlayedInteractor,
) :
    Presenter.Factory {
    override fun create(
        screen: Screen,
        navigator: Navigator,
        context: CircuitContext,
    ): Presenter<*>? {
        return when (screen) {
            is PlayerScreen -> PlayerPresenter(
                navigator = navigator,
                playInteractor,
                pauseInteractor,
                stopInteractor,
                resumeInteractor,
                nowPlayingInteractor,
                observePlayStateInteractor,
                lastPlayedInteractor,
            )

            else -> null
        }
    }
}

class PlayerPresenter(
    private val navigator: Navigator,
    private val playInteractor: PlayInteractor,
    private val pauseInteractor: PauseInteractor,
    private val stopInteractor: StopInteractor,
    private val resumeInteractor: ResumePlaybackInteractor,
    private val nowPlayingInteractor: ObserveNowPlayingInteractor,
    private val observePlayStateInteractor: ObservePlayStateInteractor,
    private val lastPlayedInteractor: GetLastPlayedInteractor,
) : Presenter<PlayerUiState> {
    @Composable
    override fun present(): PlayerUiState {

        val scope = rememberCoroutineScope()

        val playState by rememberRetained { observePlayStateInteractor() }
            .collectAsRetainedState(PlayState.Idle)

        val nowPlaying by rememberRetained {
            nowPlayingInteractor().map { it ?: lastPlayedInteractor() }
        }.collectAsRetainedState(null)

        fun eventSink(event: PlayerUiEvent) {
            when (event) {
                PlayerUiEvent.Pause -> pauseInteractor()
                PlayerUiEvent.Play ->
                    if (playState == PlayState.Paused) resumeInteractor()
                    else scope.launch {
                        nowPlaying?.let {
                            playInteractor(
                                it.episode,
                                it.currentPosition,
                            )
                        }
                    }

                PlayerUiEvent.Stop -> stopInteractor()
            }
        }

        return PlayerUiState(
            nowPlaying = nowPlaying,
            eventSink = ::eventSink,
        )
    }
}
