package studio.goodegg.capsule.details.podcast

import androidx.compose.runtime.Immutable
import com.slack.circuit.runtime.CircuitUiState
import studio.goodegg.capsule.Podcast
import studio.goodegg.capsule.EpisodeAndPlayState

@Immutable
data class PodcastDetailsUiState(
    val loading: Boolean,
    val isSubscribed: Boolean,
    val podcast: Podcast?,
    val playedEpisodesSlugs: Set<String>,
    val nowPlaying: EpisodeAndPlayState?,
    val eventSink: (PodcastDetailsUiEvent) -> Unit,
) : CircuitUiState
