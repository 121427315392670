package studio.goodegg.capsule.ui.components

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.size
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButtonDefaults
import androidx.compose.material3.LocalContentColor
import androidx.compose.material3.OutlinedButton
import androidx.compose.material3.OutlinedIconButton
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.unit.dp
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyText
import io.daio.pancake.foundations.text.BodyTextSmall
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.Tier

// TODO move to pancake
@Composable
fun IconTextButton(
    icon: ImageVector,
    text: String,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    enabled: Boolean = true,
) {
    OutlinedButton(
        onClick = onClick,
        enabled = enabled,
        modifier = modifier.height(32.dp),
        shape = Theme.shapes.circle,
        colors = ButtonDefaults.buttonColors(
            contentColor = Theme.colors.onSurfaceBackground,
            containerColor = Color.Transparent,
        ),
    ) {
        Tier(spaceBetween = LayoutGap.Tiny) {
            Icon(
                imageVector = icon,
                modifier = Modifier.size(14.dp),
                tint = LocalContentColor.current,
                contentDescription = "",
            )
            BodyTextSmall(text, textColor = LocalContentColor.current)
        }
    }
}

@Composable
fun OutlineIconButton(
    icon: ImageVector,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
) {
    OutlinedIconButton(
        onClick = onClick,
        modifier = modifier.height(32.dp),
        shape = Theme.shapes.circle,
        colors = IconButtonDefaults.iconButtonColors(
            contentColor = Theme.colors.onSurfaceBackground,
            containerColor = Color.Transparent,
        ),
        border = BorderStroke(
            1.0.dp,
            color = Theme.colors.onSurfaceBackground.copy(.4f),
        ),
    ) {
        Icon(
            imageVector = icon,
            modifier = Modifier.size(14.dp),
            tint = LocalContentColor.current,
            contentDescription = "",
        )
    }
}

