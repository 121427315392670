package studio.goodegg.capsule.db

import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlDriver
import app.cash.sqldelight.db.SqlSchema
import app.cash.sqldelight.driver.worker.WebWorkerDriver
import me.tatarka.inject.annotations.Provides
import org.w3c.dom.Worker
import studio.goodegg.capsule.common.di.ApplicationScope

actual interface DbDriverComponent {
    @Provides
    @ApplicationScope
    fun providesDbDriver(
        schema: SqlSchema<QueryResult.AsyncValue<Unit>>,
    ): SqlDriver = WebWorkerDriver(
        Worker(
            js("""new URL("@cashapp/sqldelight-sqljs-worker/sqljs.worker.js", import.meta.url)"""),
        ),
    )

    @Provides
    @ApplicationScope
    fun providesDbCreator(
        schema: SqlSchema<QueryResult.AsyncValue<Unit>>,
        driver: SqlDriver,
    ): CapsuleDbCreator = CapsuleDbCreator(schema, driver)
}