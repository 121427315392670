package studio.goodegg.capsule.resume

import com.slack.circuit.runtime.CircuitUiEvent
import studio.goodegg.capsule.Episode
import studio.goodegg.capsule.domain.playback.EpisodeAndPosition

sealed class ResumeUiEvent : CircuitUiEvent {
    data class Open(val id: String) : ResumeUiEvent()
    data class Resume(val episode: EpisodeAndPosition) : ResumeUiEvent()
    data class MarkAsPlayed(val episode: Episode) : ResumeUiEvent()
    data class UnMarkAsPlayed(val episode: Episode) : ResumeUiEvent()
}
