package studio.goodegg.capsule.db

import kotlin.Long
import kotlin.String

public data class Dbepisode(
  public val episodeSlug: String,
  public val title: String,
  public val author: String,
  public val created: Long,
  public val description: String?,
  public val image: String?,
  public val media: String,
  public val mediaType: String,
  public val fileSize: Long?,
  public val duration: Long?,
  public val parentFeed: String,
  public val parent_slug: String,
)
