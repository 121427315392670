package studio.goodegg.capsule.db

import kotlin.Long
import kotlin.String

public data class Dbpodcast(
  public val slug: String,
  public val id: Long,
  public val title: String,
  public val author: String,
  public val feed: String,
  public val collectionId: Long,
  public val artistId: Long?,
  public val thumbnail: String,
  public val imageUrl: String,
  public val trackCount: Long?,
  public val primaryGenreName: String,
  public val summary: String,
  public val categories: String?,
  public val feedimage: String?,
)
