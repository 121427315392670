package io.daio.pancake.components.text

import androidx.compose.foundation.text.selection.LocalTextSelectionColors
import androidx.compose.material3.TextFieldColors
import androidx.compose.material3.TextFieldDefaults
import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.ui.graphics.Color
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.color.disabled

object TextAreaDefaults {
    @Composable
    fun colors(): TextAreaColors =
        TextAreaColors(
            focusedTextColor = Theme.colors.onSurfaceBackground,
            unfocusedTextColor = Theme.colors.onSurfaceBackground,
            disabledTextColor = Theme.colors.onSurfaceBackground.disabled(),
            errorTextColor = Theme.colors.negative,
            focusedContainerColor = Color.Transparent,
            unfocusedContainerColor = Color.Transparent,
            disabledContainerColor = Color.Transparent,
            errorContainerColor = Color.Transparent,
            cursorColor = Theme.colors.onSurfaceBackground,
            errorCursorColor = Theme.colors.negative,
            focusedIndicatorColor = Color.Transparent,
            unfocusedIndicatorColor = Color.Transparent,
            disabledIndicatorColor = Color.Transparent,
            errorIndicatorColor = Color.Transparent,
            focusedLeadingIconColor = Theme.colors.onSurfaceBackground,
            unfocusedLeadingIconColor = Theme.colors.onSurfaceBackground,
            disabledLeadingIconColor = Theme.colors.onSurfaceBackground.disabled(),
            errorLeadingIconColor = Theme.colors.negative,
            focusedTrailingIconColor = Theme.colors.onSurfaceBackground,
            unfocusedTrailingIconColor = Theme.colors.onSurfaceBackground,
            disabledTrailingIconColor = Theme.colors.onSurfaceBackground.disabled(),
            errorTrailingIconColor = Theme.colors.negative,
            focusedLabelColor = Theme.colors.onSurfaceBackground,
            unfocusedLabelColor = Theme.colors.onSurfaceBackground,
            disabledLabelColor = Theme.colors.onSurfaceBackground.disabled(),
            errorLabelColor = Theme.colors.negative.disabled(),
            focusedPlaceholderColor = Theme.colors.onSurfaceBackground,
            unfocusedPlaceholderColor = Theme.colors.onSurfaceBackground,
            disabledPlaceholderColor = Theme.colors.onSurfaceBackground.disabled(),
            errorPlaceholderColor = Theme.colors.negative,
            focusedSupportingTextColor = Theme.colors.onSurfaceBackground,
            unfocusedSupportingTextColor = Theme.colors.onSurfaceBackground,
            disabledSupportingTextColor = Theme.colors.onSurfaceBackground.disabled(),
        )
}

@Immutable
data class TextAreaColors(
    val focusedTextColor: Color,
    val unfocusedTextColor: Color,
    val disabledTextColor: Color,
    val errorTextColor: Color,
    val focusedContainerColor: Color,
    val unfocusedContainerColor: Color,
    val disabledContainerColor: Color,
    val errorContainerColor: Color,
    val cursorColor: Color,
    val errorCursorColor: Color,
    val focusedIndicatorColor: Color,
    val unfocusedIndicatorColor: Color,
    val disabledIndicatorColor: Color,
    val errorIndicatorColor: Color,
    val focusedLeadingIconColor: Color,
    val unfocusedLeadingIconColor: Color,
    val disabledLeadingIconColor: Color,
    val errorLeadingIconColor: Color,
    val focusedTrailingIconColor: Color,
    val unfocusedTrailingIconColor: Color,
    val disabledTrailingIconColor: Color,
    val errorTrailingIconColor: Color,
    val focusedLabelColor: Color,
    val unfocusedLabelColor: Color,
    val disabledLabelColor: Color,
    val errorLabelColor: Color,
    val focusedPlaceholderColor: Color,
    val unfocusedPlaceholderColor: Color,
    val disabledPlaceholderColor: Color,
    val errorPlaceholderColor: Color,
    val focusedSupportingTextColor: Color,
    val unfocusedSupportingTextColor: Color,
    val disabledSupportingTextColor: Color,
)

@Composable
internal fun TextAreaColors.toTextFieldColors(): TextFieldColors =
    TextFieldDefaults.colors(
        focusedTextColor = focusedTextColor,
        unfocusedTextColor = unfocusedTextColor,
        disabledTextColor = disabledTextColor,
        errorTextColor = errorTextColor,
        focusedContainerColor = focusedContainerColor,
        unfocusedContainerColor = unfocusedContainerColor,
        disabledContainerColor = disabledContainerColor,
        errorContainerColor = errorContainerColor,
        cursorColor = cursorColor,
        errorCursorColor = errorCursorColor,
        selectionColors = LocalTextSelectionColors.current,
        focusedIndicatorColor = focusedIndicatorColor,
        unfocusedIndicatorColor = unfocusedIndicatorColor,
        disabledIndicatorColor = disabledIndicatorColor,
        errorIndicatorColor = errorIndicatorColor,
        focusedLeadingIconColor = focusedLeadingIconColor,
        unfocusedLeadingIconColor = unfocusedLeadingIconColor,
        disabledLeadingIconColor = disabledLeadingIconColor,
        errorLeadingIconColor = errorLeadingIconColor,
        focusedTrailingIconColor = focusedTrailingIconColor,
        unfocusedTrailingIconColor = unfocusedTrailingIconColor,
        disabledTrailingIconColor = disabledTrailingIconColor,
        errorTrailingIconColor = errorTrailingIconColor,
        focusedLabelColor = focusedLabelColor,
        unfocusedLabelColor = unfocusedLabelColor,
        disabledLabelColor = disabledLabelColor,
        errorLabelColor = errorLabelColor,
        focusedPlaceholderColor = focusedPlaceholderColor,
        unfocusedPlaceholderColor = unfocusedPlaceholderColor,
        disabledPlaceholderColor = disabledPlaceholderColor,
        errorPlaceholderColor = errorPlaceholderColor,
        focusedSupportingTextColor = focusedSupportingTextColor,
        unfocusedSupportingTextColor = unfocusedSupportingTextColor,
        disabledSupportingTextColor = disabledSupportingTextColor,
    )
