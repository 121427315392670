package studio.goodegg.capsule.inbox

import androidx.compose.runtime.Immutable
import com.slack.circuit.runtime.CircuitUiState
import studio.goodegg.capsule.Episode
import studio.goodegg.capsule.PodcastSubscription
import studio.goodegg.capsule.EpisodeAndPlayState

@Immutable
data class InboxUiState(
    val subscriptions: List<PodcastSubscription>,
    val latestEpisodes: List<Episode>,
    val nowPlaying: EpisodeAndPlayState?,
    val eventSink: (InboxUiEvent) -> Unit,
) : CircuitUiState
