package studio.goodegg.capsule.inbox

import com.slack.circuit.runtime.CircuitUiEvent
import studio.goodegg.capsule.Episode

sealed class InboxUiEvent : CircuitUiEvent {
    data class Open(val slug: String) : InboxUiEvent()
    data class PlayEpisode(val episode: Episode) : InboxUiEvent()
    data class DownloadEpisode(val episode: Episode) : InboxUiEvent()
    data class ShareEpisode(val episode: Episode) : InboxUiEvent()
    data object ViewAllSubscriptions : InboxUiEvent()
}
