package io.daio.bass

import kotlinx.coroutines.flow.Flow

// Todo these can pass data in the future.
sealed interface PlayerState {
    data object Idle : PlayerState

    data object Playing : PlayerState

    data object Paused : PlayerState

    data object Stopped : PlayerState

    data object Error : PlayerState

    data object Buffering : PlayerState
}

expect fun BassPlayer.playbackStateChanges(): Flow<PlayerState>

/**
 * A data class that represents the progress of a media playback.
 *
 * @param duration the total duration of the media in milliseconds
 * @param progress the current playback position in milliseconds
 * @param percentage the current playback position as a percentage of the
 * total duration within range 0.0 - 1.0f.
 */
data class PlaybackProgress(
    val duration: Long,
    val progress: Long,
    val percentage: Float,
)

expect fun BassPlayer.playbackProgressChanges(): Flow<PlaybackProgress>
