package studio.goodegg.capsule.api

import io.ktor.client.HttpClient
import io.ktor.client.plugins.cache.HttpCache
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.client.plugins.logging.Logging
import io.ktor.serialization.kotlinx.json.json
import kotlinx.serialization.json.Json
import me.tatarka.inject.annotations.Provides
import studio.goodegg.capsule.common.di.ApplicationScope

actual interface NetworkClientComponent {
    @Provides
    @ApplicationScope
    fun providesKtorClient(): HttpClient = HttpClient {
        install(HttpCache)
        install(Logging)
        install(ContentNegotiation) {
            json(
                Json {
                    isLenient = true
                    ignoreUnknownKeys = true
                    prettyPrint = true
                },
            )
        }
    }
}