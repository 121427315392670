package studio.goodegg.capsule.domain.subscription

import kotlinx.coroutines.flow.map
import me.tatarka.inject.annotations.Component
import me.tatarka.inject.annotations.Provides
import studio.goodegg.capsule.repositories.SubscriptionsRepository

@Component
interface SubscriptionDomainComponent {
    @Provides
    fun providesGetAllSubscriptionsInteractor(subscriptionsRepository: SubscriptionsRepository): GetAllSubscriptionsInteractor =
        GetAllSubscriptionsInteractor { subscriptionsRepository.getAll() }

    @Provides
    fun providesObserveAllSubscriptionsInteractor(subscriptionsRepository: SubscriptionsRepository): ObserveAllSubscriptionsInteractor =
        ObserveAllSubscriptionsInteractor { subscriptionsRepository.observeAll() }

    @Provides
    fun providesObserveSubscriptionInteractor(subscriptionsRepository: SubscriptionsRepository): ObserveSubscriptionInteractor =
        ObserveSubscriptionInteractor {
            subscriptionsRepository.observeSubscription(it).map { it != null }
        }

    @Provides
    fun providesDeleteSubscriptionInteractor(subscriptionsRepository: SubscriptionsRepository): DeleteSubscriptionInteractor =
        DeleteSubscriptionInteractor { slug -> subscriptionsRepository.delete(slug) }

    @Provides
    fun providesAddSubscriptionInteractor(subscriptionsRepository: SubscriptionsRepository): AddSubscriptionInteractor =
        AddSubscriptionInteractor { podcastSubscription ->
            subscriptionsRepository.insert(
                podcastSubscription,
            )
        }
}
