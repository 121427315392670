package io.daio.pancake.foundations.text

import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextLayoutResult
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow

@Composable
fun BodyTextLarge(
    text: String,
    modifier: Modifier = Modifier,
    textColor: Color = Color.Unspecified,
    onTextLayout: ((TextLayoutResult) -> Unit)? = null,
    overflow: TextOverflow = TextOverflow.Clip,
    softWrap: Boolean = true,
    maxLines: Int = Int.MAX_VALUE,
    minLines: Int = 1,
    textAlign: TextAlign = TextAlign.Start,
) {
    BaseText(
        text = text,
        onTextLayout = onTextLayout,
        overflow = overflow,
        softWrap = softWrap,
        maxLines = maxLines,
        minLines = minLines,
        modifier = modifier,
        textStyle = mainTypography.bodyLarge,
        textColor = textColor,
        textAlign = textAlign,
    )
}

@Composable
fun BodyText(
    text: String,
    modifier: Modifier = Modifier,
    textColor: Color = Color.Unspecified,
    onTextLayout: ((TextLayoutResult) -> Unit)? = null,
    overflow: TextOverflow = TextOverflow.Clip,
    softWrap: Boolean = true,
    maxLines: Int = Int.MAX_VALUE,
    minLines: Int = 1,
    textAlign: TextAlign = TextAlign.Start,
) {
    BaseText(
        text = text,
        modifier = modifier,
        onTextLayout = onTextLayout,
        overflow = overflow,
        softWrap = softWrap,
        maxLines = maxLines,
        minLines = minLines,
        textStyle = mainTypography.bodyDefault,
        textColor = textColor,
        textAlign = textAlign,
    )
}

@Composable
fun BodyTextSmall(
    text: String,
    modifier: Modifier = Modifier,
    textColor: Color = Color.Unspecified,
    onTextLayout: ((TextLayoutResult) -> Unit)? = null,
    overflow: TextOverflow = TextOverflow.Clip,
    softWrap: Boolean = true,
    maxLines: Int = Int.MAX_VALUE,
    minLines: Int = 1,
    textAlign: TextAlign = TextAlign.Start,
) {
    BaseText(
        text = text,
        modifier = modifier,
        onTextLayout = onTextLayout,
        overflow = overflow,
        softWrap = softWrap,
        maxLines = maxLines,
        minLines = minLines,
        textStyle = mainTypography.bodySmall,
        textColor = textColor,
        textAlign = textAlign,
    )
}
