package studio.goodegg.capsule.base.main

import androidx.compose.animation.AnimatedContent
import androidx.compose.animation.SizeTransform
import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.animation.togetherWith
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.runtime.Composable
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import com.slack.circuit.backstack.NavDecoration
import com.slack.circuit.backstack.SaveableBackStack
import com.slack.circuit.backstack.rememberSaveableBackStack
import com.slack.circuit.foundation.Circuit
import com.slack.circuit.foundation.CircuitCompositionLocals
import com.slack.circuit.foundation.CircuitContent
import com.slack.circuit.foundation.NavigableCircuitContent
import com.slack.circuit.foundation.NavigatorDefaults.DefaultDecoration.backward
import com.slack.circuit.foundation.NavigatorDefaults.DefaultDecoration.forward
import com.slack.circuit.foundation.rememberCircuitNavigator
import com.slack.circuit.runtime.Navigator
import io.daio.pancake.components.bottombar.BarItem
import io.daio.pancake.components.bottombar.BottomBar
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.color.darkColors
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.Stack
import io.daio.pancake.scaffold.Scaffold
import kotlinx.collections.immutable.ImmutableList
import me.tatarka.inject.annotations.Assisted
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.navigation.DiscoverScreen
import studio.goodegg.capsule.navigation.InboxScreen
import studio.goodegg.capsule.navigation.ListenScreen
import studio.goodegg.capsule.navigation.PlayerScreen
import studio.goodegg.capsule.navigation.SubscriptionsScreen

@Composable
@Inject
fun MainApp(
    circuit: Circuit,
    @Assisted modifier: Modifier = Modifier,
    @Assisted backStack: SaveableBackStack = rememberSaveableBackStack(root = InboxScreen),
    @Assisted navigator: Navigator = rememberCircuitNavigator(backStack) {},
) {
    val current =
        remember(backStack) {
            derivedStateOf { backStack.last().screen }
        }

    val bottomBar: (@Composable () -> Unit) =
        {
            BottomBar {
                BarItem(
                    "Home",
                    null,
                    selected = current.value == InboxScreen,
                    modifier = Modifier.weight(1f),
                    onClick = {
                        navigator.resetRoot(InboxScreen)
                    },
                )
                BarItem(
                    "Subscriptions",
                    null,
                    selected = current.value == SubscriptionsScreen,
                    modifier = Modifier.weight(1f),
                    onClick = {
                        navigator.resetRoot(SubscriptionsScreen)
                    },
                )
                BarItem(
                    "Trending",
                    null,
                    selected = current.value == DiscoverScreen,
                    modifier = Modifier.weight(1f),
                    onClick = {
                        navigator.resetRoot(DiscoverScreen)
                    },
                )
                BarItem(
                    "Listen",
                    null,
                    selected = current.value == ListenScreen,
                    modifier = Modifier.weight(1f),
                    onClick = {
                        navigator.resetRoot(ListenScreen)
                    },
                )
            }
        }

    CircuitCompositionLocals(circuit) {
        Scaffold(
            modifier = modifier,
            themeColors = darkColors.copy(accent = Color(0xFFFCC457)),
            bottomBar = bottomBar,
        ) {
            Stack(
                spaceBetween = LayoutGap.None,
                modifier = Modifier.fillMaxSize().padding(
                    PaddingValues(
                        top = it.calculateTopPadding(),
                        start = Theme.dimens.size_m,
                        end = Theme.dimens.size_m,
                        bottom = it.calculateBottomPadding(),
                    ),
                ),
            ) {
                NavigableCircuitContent(
                    modifier = Modifier.weight(1f),
                    navigator = navigator,
                    backStack = backStack,
                    decoration = CapsuleDecor,
                )
                CircuitContent(PlayerScreen())
            }
        }
    }
}

private object CapsuleDecor : NavDecoration {
    @Composable
    override fun <T> DecoratedContent(
        args: ImmutableList<T>,
        backStackDepth: Int,
        modifier: Modifier,
        content: @Composable (T) -> Unit,
    ) {
        AnimatedContent(
            targetState = args,
            modifier = modifier,
            transitionSpec = {
                (fadeIn() togetherWith fadeOut()).using(
                    // Disable clipping since the faded slide-in/out should
                    // be displayed out of bounds.
                    SizeTransform(clip = false),
                )
            },
        ) {
            content.invoke(it.first())
        }
    }
}