package io.daio.pancake.foundations

import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material3.Surface
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.ReadOnlyComposable
import androidx.compose.runtime.staticCompositionLocalOf
import androidx.compose.ui.Modifier
import io.daio.pancake.foundations.color.Colors
import io.daio.pancake.foundations.color.contentColorFor
import io.daio.pancake.foundations.color.darkColors
import io.daio.pancake.foundations.shape.PancakeShapes
import io.daio.pancake.foundations.shape.mainShapes
import io.daio.pancake.foundations.size.Dimens
import io.daio.pancake.foundations.size.mainDimens
import io.daio.pancake.foundations.text.Typography
import io.daio.pancake.foundations.text.mainTypography

object Theme {
    val colors: Colors
        @ReadOnlyComposable
        @Composable
        get() = LocalColors.current

    val dimens: Dimens = mainDimens

    val shapes: PancakeShapes
        @ReadOnlyComposable
        @Composable
        get() = LocalShapes.current

    val typography: Typography
        @ReadOnlyComposable
        @Composable
        get() = LocalTypography.current
}

@Composable
fun PancakeTheme(
    modifier: Modifier = Modifier,
    themeColors: Colors = Theme.colors,
    shapes: PancakeShapes = Theme.shapes,
    typography: Typography = Theme.typography,
    content: @Composable
    () -> Unit,
) {
    CompositionLocalProvider(
        LocalColors provides themeColors,
        LocalShapes provides shapes,
        LocalTypography provides typography,
    ) {
        Surface(
            color = Theme.colors.surfaceBackground,
            contentColor = contentColorFor(Theme.colors.surfaceBackground),
            modifier = modifier.fillMaxSize(),
            content = content,
        )
    }
}

internal val LocalTypography = staticCompositionLocalOf { mainTypography }

internal val LocalShapes = staticCompositionLocalOf { mainShapes }

internal val LocalColors = staticCompositionLocalOf { darkColors }
