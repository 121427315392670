package studio.goodegg.capsule.base.main

import com.slack.circuit.foundation.Circuit
import me.tatarka.inject.annotations.Component
import studio.goodegg.capsule.common.di.ActivityScope
import studio.goodegg.capsule.common.di.ApplicationScope

@Component
@ApplicationScope
abstract class JsAppComponent : SharedAppComponent {
    companion object
}

@Component
@ActivityScope
abstract class JsUiComponent(
    @Component val appComponent: JsAppComponent,
) : SharedUiComponent {
    abstract val circuit: Circuit

    companion object
}
