package studio.goodegg.capsule

import kotlinx.serialization.Serializable

@Serializable
data class SearchResult(
    val podcasts: List<PodcastResult> = emptyList(),
    val episodes: List<Episode> = emptyList(),
)

@Serializable
data class PodcastResult(
    val author: String,
    val title: String,
    val imageUrl: String?,
    val feed: String,
    val thumbnailUrl: String,
    val primaryGenreName: String,
    val slug: String,
)
