package studio.goodegg.capsule.player

import androidx.compose.runtime.Immutable
import com.slack.circuit.runtime.CircuitUiState
import studio.goodegg.capsule.EpisodeAndPlayState

@Immutable
data class PlayerUiState(
    val nowPlaying: EpisodeAndPlayState?,
    val eventSink: (PlayerUiEvent) -> Unit,
) : CircuitUiState
