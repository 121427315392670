package io.daio.pancake.components.snackbar

import androidx.compose.material.SnackbarHost
import androidx.compose.material.SnackbarHostState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.Stable
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier

@Composable
fun SnackbarHolder(
    snackbarState: SnackbarState,
    modifier: Modifier = Modifier,
) {
    SnackbarHost(
        hostState = snackbarState.snackbarHostState,
        modifier = modifier,
        snackbar = { Snackbar(it.message) },
    )
}

@Stable
class SnackbarState(internal val snackbarHostState: SnackbarHostState) {
    suspend fun showSnackBar(message: String) {
        snackbarHostState.showSnackbar(message)
    }
}

@Composable
fun rememberSnackbarState() = remember { createSnackbarState() }

fun createSnackbarState() = SnackbarState(SnackbarHostState())
