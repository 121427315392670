package studio.goodegg.capsule.trending

import androidx.compose.runtime.Immutable
import com.slack.circuit.runtime.CircuitUiState
import studio.goodegg.capsule.TrendingRow

@Immutable
data class TrendingUiState(
    val refreshing: Boolean,
    val subscriptions: Set<String>,
    val items: List<TrendingRow>,
    val eventSink: (TrendingUiEvent) -> Unit,
) : CircuitUiState
