package studio.goodegg.capsule.repositories

import app.cash.sqldelight.async.coroutines.awaitAsList
import app.cash.sqldelight.coroutines.asFlow
import app.cash.sqldelight.coroutines.mapToList
import app.cash.sqldelight.coroutines.mapToOneOrNull
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flowOn
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.withContext
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.PodcastSubscription
import studio.goodegg.capsule.common.coroutines.IoDispatcher
import studio.goodegg.capsule.db.CapsuleDbCreator
import studio.goodegg.capsule.db.asFlow

interface SubscriptionsRepository {
    suspend fun getAll(): List<PodcastSubscription>

    suspend fun insert(podcastSubscription: PodcastSubscription)

    suspend fun delete(slug: String)

    fun observeAll(): Flow<List<PodcastSubscription>>

    fun observeSubscription(slug: String): Flow<PodcastSubscription?>
}

@Inject
internal class SubscriptionsRepositoryImpl(
    private val db: CapsuleDbCreator,
    @IoDispatcher private val ioDispatcher: CoroutineDispatcher,
) : SubscriptionsRepository {

    private suspend fun queries() = db.get().subscriptionQueries

    override suspend fun getAll(): List<PodcastSubscription> = withContext(ioDispatcher) {
        queries().selectAll().awaitAsList().map {
            PodcastSubscription(
                slug = it.slug,
                feedUrl = it.feedurl,
                title = it.title,
                artist = it.artist,
                image = it.image,
                notify = it.notify,
                collection = it.collection,
                autoDownload = it.autodownload,
            )
        }
    }

    override suspend fun insert(podcastSubscription: PodcastSubscription) {
        queries().insert(
            slug = podcastSubscription.slug,
            feedurl = podcastSubscription.feedUrl,
            title = podcastSubscription.title,
            artist = podcastSubscription.artist,
            image = podcastSubscription.image,
            collection = podcastSubscription.collection,
            notify = podcastSubscription.notify,
            autodownload = podcastSubscription.autoDownload,
        )
    }

    override suspend fun delete(slug: String) {
        queries().delete(slug = slug)
    }

    override fun observeAll(): Flow<List<PodcastSubscription>> {
        return db.asFlow {
            subscriptionQueries.selectAll().asFlow()
                .mapToList(ioDispatcher)
                .map { subs ->
                    subs.map {
                        PodcastSubscription(
                            slug = it.slug,
                            feedUrl = it.feedurl,
                            title = it.title,
                            artist = it.artist,
                            image = it.image,
                            notify = it.notify,
                            collection = it.collection,
                            autoDownload = it.autodownload,
                        )
                    }
                }.flowOn(ioDispatcher)
        }
    }

    override fun observeSubscription(slug: String): Flow<PodcastSubscription?> {
        return db.asFlow {
            subscriptionQueries.selectSubscription(slug)
                .asFlow()
                .mapToOneOrNull(ioDispatcher)
                .map { sub ->
                    sub?.let {
                        PodcastSubscription(
                            slug = it.slug,
                            feedUrl = it.feedurl,
                            title = it.title,
                            artist = it.artist,
                            image = it.image,
                            notify = it.notify,
                            collection = it.collection,
                            autoDownload = it.autodownload,
                        )
                    }
                }
        }
    }
}
