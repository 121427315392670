package studio.goodegg.capsule.inbox

import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.lazy.items
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.minimumInteractiveComponentSize
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.screen.Screen
import com.slack.circuit.runtime.ui.Ui
import com.slack.circuit.runtime.ui.ui
import compose.icons.FeatherIcons
import compose.icons.feathericons.DownloadCloud
import compose.icons.feathericons.Pause
import compose.icons.feathericons.Play
import compose.icons.feathericons.Share2
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyText
import io.daio.pancake.foundations.text.BodyTextSmall
import io.daio.pancake.foundations.text.LinkText
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.LazyStack
import io.daio.pancake.layout.LazyTier
import io.daio.pancake.layout.Stack
import io.daio.pancake.layout.Tier
import studio.goodegg.capsule.Episode
import studio.goodegg.capsule.PlayState
import studio.goodegg.capsule.EpisodeAndPlayState
import studio.goodegg.capsule.formatCreatedDate
import studio.goodegg.capsule.formatTimeRemaining
import studio.goodegg.capsule.navigation.InboxScreen
import studio.goodegg.capsule.ui.components.ArtworkTile
import studio.goodegg.capsule.ui.components.EpisodeItem
import studio.goodegg.capsule.ui.components.IconTextButton

class InboxScreenUiFactory : Ui.Factory {
    override fun create(
        screen: Screen,
        context: CircuitContext,
    ): Ui<*>? {
        return when (screen) {
            is InboxScreen -> inbox()
            else -> null
        }
    }
}

private fun inbox() =
    ui<InboxUiState> { state, modifier ->
        Inbox(
            state,
            modifier,
        )
    }

@OptIn(ExperimentalFoundationApi::class)
@Composable
fun Inbox(
    state: InboxUiState,
    modifier: Modifier = Modifier,
) {
    Stack(modifier, spaceBetween = LayoutGap.Large) {
        BodyText("Subscriptions")
        LazyTier {
            item {
                BodyTextSmall(
                    "View All",
                    textAlign = TextAlign.Center,
                    modifier = Modifier.size(100.dp)
                        .clickable { state.eventSink(InboxUiEvent.ViewAllSubscriptions) },
                )
            }

            items(
                state.subscriptions,
                key = { it.slug },
            ) { sub ->
                ArtworkTile(
                    modifier = Modifier.animateItemPlacement(),
                    size = 100.dp,
                    imageUrl = sub.image,
                    onClick = { state.eventSink(InboxUiEvent.Open(sub.slug)) },
                )
            }
        }

        BodyText("New Episodes")
        LazyStack {
            items(
                state.latestEpisodes,
                key = { it.episodeSlug },
            ) {
                Stack(modifier = Modifier.animateItemPlacement()) {
                    EpisodeItem(
                        episode = it,
                        nowPlaying = state.nowPlaying,
                        onPlay = { state.eventSink(InboxUiEvent.PlayEpisode(it)) },
                        onShare = { state.eventSink(InboxUiEvent.ShareEpisode(it)) },
                        onDownload = { state.eventSink(InboxUiEvent.DownloadEpisode(it)) },
                    )
                    HorizontalDivider(color = Theme.colors.onSurfaceBackground.copy(alpha = .2f))
                }
            }
        }
    }
}
