package studio.goodegg.capsule.resume

import androidx.compose.runtime.Immutable
import com.slack.circuit.runtime.CircuitUiState
import studio.goodegg.capsule.EpisodeAndPlayState
import studio.goodegg.capsule.domain.playback.EpisodeAndPosition

@Immutable
data class ResumeUiState(
    val items: List<EpisodeAndPosition>,
    val nowPlaying: EpisodeAndPlayState?,
    val eventSink: (ResumeUiEvent) -> Unit,
) : CircuitUiState
