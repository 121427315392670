package studio.goodegg.capsule.ui.components

import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.size
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import coil3.compose.AsyncImage
import coil3.compose.LocalPlatformContext
import coil3.request.ImageRequest
import coil3.request.crossfade
import io.daio.pancake.foundations.Theme

const val IMAGE_TRANSCODE_URL =
    "https://kycrpvroitgvdzlozrbj.supabase.co/functions/v1/image-resizer?url="

@Composable
fun ArtworkTile(
    imageUrl: String?,
    modifier: Modifier = Modifier,
    onClick: (() -> Unit)? = null,
    size: Dp = 200.dp,
) {
    val imageShape = Theme.shapes.large
    val pixelSize = with(LocalDensity.current) { size.roundToPx() }
    val internalModifier = if (onClick != null) modifier.clickable { onClick() } else modifier

    val context = LocalPlatformContext.current
    val request = remember(imageUrl) {
        ImageRequest.Builder(context)
            .data("${IMAGE_TRANSCODE_URL}$imageUrl&width=$pixelSize")
            .crossfade(true)
            .build()
    }

    AsyncImage(
        request,
        contentDescription = "",
        modifier = internalModifier
            .size(size)
            .graphicsLayer {
                shape = imageShape
                clip = true
            },
    )
}
