package studio.goodegg.capsule.trending

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Icon
import androidx.compose.material3.minimumInteractiveComponentSize
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.onFocusChanged
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.semantics.Role
import androidx.compose.ui.semantics.role
import androidx.compose.ui.semantics.semantics
import androidx.compose.ui.unit.dp
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.screen.Screen
import com.slack.circuit.runtime.ui.Ui
import com.slack.circuit.runtime.ui.ui
import compose.icons.FeatherIcons
import compose.icons.feathericons.Bookmark
import compose.icons.feathericons.DownloadCloud
import compose.icons.feathericons.Plus
import compose.icons.feathericons.PlusCircle
import io.daio.pancake.components.loading.FullScreenLoadingIndicator
import io.daio.pancake.components.search.SearchBar
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyText
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.LazyStack
import io.daio.pancake.layout.LazyTier
import io.daio.pancake.layout.Stack
import studio.goodegg.capsule.PodcastResult
import studio.goodegg.capsule.TrendingRow
import studio.goodegg.capsule.navigation.DiscoverScreen
import studio.goodegg.capsule.ui.components.ArtworkTile

class TrendingScreenUiFactory : Ui.Factory {
    override fun create(
        screen: Screen,
        context: CircuitContext,
    ): Ui<*>? {
        return when (screen) {
            is DiscoverScreen -> trending()
            else -> null
        }
    }
}

private fun trending() =
    ui<TrendingUiState> { state, modifier ->
        Trending(
            state,
            modifier,
        )
    }

@Composable
fun Trending(
    state: TrendingUiState,
    modifier: Modifier = Modifier,
) {
    Box(modifier = modifier.fillMaxSize()) {
        if (state.refreshing)
            FullScreenLoadingIndicator()

        Stack(modifier = modifier) {
            Spacer(modifier = Modifier.height(Theme.dimens.size_xxxs))
            SearchBar(
                "",
                onValueChange = {
                },
                modifier =
                Modifier.onFocusChanged {
                    if (it.isFocused) {
                        state.eventSink(TrendingUiEvent.OpenSearch)
                    }
                },
            )
            LazyStack(modifier = Modifier.fillMaxSize()) {
                items(state.items) {
                    TrendingRow(
                        it,
                        isSubscribed = {
                            state.subscriptions.contains(it.slug)
                        },
                        onSubscribe = { podcast ->
                            state.eventSink(TrendingUiEvent.AddToSubscriptions(podcast))
                        },
                        onClick = { podcast ->
                            state.eventSink(TrendingUiEvent.OpenPodcast(podcast.slug))
                        },
                    )
                }
            }
        }
    }
}

@Composable
private fun TrendingRow(
    trending: TrendingRow,
    onClick: (PodcastResult) -> Unit,
    isSubscribed: (PodcastResult) -> Boolean,
    onSubscribe: (PodcastResult) -> Unit,
    modifier: Modifier = Modifier,
) {
    Stack(modifier = modifier, spaceBetween = LayoutGap.Tiny) {
        BodyText("Trending in ${trending.name}")
        LazyTier {
            items(trending.podcasts) {
                Box {
                    ArtworkTile(
                        size = 120.dp,
                        imageUrl = it.imageUrl,
                        onClick = {
                            onClick(it)
                        },
                    )

                    Box(
                        contentAlignment = Alignment.Center,
                        modifier = Modifier
                            .padding(end = Theme.dimens.size_xxs, bottom = Theme.dimens.size_xxs)
                            .graphicsLayer {
                                shape = RoundedCornerShape(50.dp)
                                clip = true

                            }
                            .background(Theme.colors.surfaceBackground)
                            .size(32.dp)
                            .align(Alignment.BottomEnd)
                            .minimumInteractiveComponentSize()
                            .clickable(
                                onClick = {
                                    onSubscribe(it)
                                },
                            )
                            .semantics { role = Role.Button },
                    ) {
                        Icon(
                            modifier = Modifier.padding(Theme.dimens.size_xs),
                            imageVector = if (isSubscribed(it))
                                FeatherIcons.Bookmark
                            else
                                FeatherIcons.Plus,
                            contentDescription = "subscribe",
                            tint = Theme.colors.onSurfaceBackground,
                        )
                    }
                }
            }
        }
    }
}
