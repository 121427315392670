package studio.goodegg.capsule.db

import kotlin.Long
import kotlin.String

public data class Played(
  public val slug: String,
  public val duration: Long,
  public val current_position: Long,
  public val last_played: Long,
  public val played_count: Long,
)
