package studio.goodegg.capsule.navigation

import com.slack.circuit.runtime.screen.Screen

@Parcelize
data object InboxScreen : CapsuleScreen("Inbox")

@Parcelize
data object SubscriptionsScreen : CapsuleScreen("Subscriptions")

@Parcelize
data object DiscoverScreen : CapsuleScreen("Discover")

@Parcelize
data object SearchScreen : CapsuleScreen("Search")

@Parcelize
data object ListenScreen : CapsuleScreen("Listen")

@Parcelize
data object ResumeScreen : CapsuleScreen("Resume")

@Parcelize
data class PodcastDetailsScreen(val slug: String) : CapsuleScreen("Podcast Detail")

abstract class CapsuleScreen(val name: String) : Screen

@Parcelize
data class PlayerScreen(val expand: Boolean = true) : CapsuleScreen("Player")