package studio.goodegg.capsule.domain.search

import me.tatarka.inject.annotations.Component
import me.tatarka.inject.annotations.Provides
import studio.goodegg.capsule.repositories.SearchRepository

@Component
interface SearchDomainComponent {
    @Provides
    fun providesSearchInteractor(trendingRepository: SearchRepository): SearchInteractor =
        SearchInteractor { query -> trendingRepository.search(query) }

    @Provides
    fun providesSearchPodcastsInteractor(trendingRepository: SearchRepository): SearchPodcastsInteractor =
        SearchPodcastsInteractor { query -> trendingRepository.searchPodcasts(query) }

    @Provides
    fun providesSearchEpisodesInteractor(trendingRepository: SearchRepository): SearchEpisodesInteractor =
        SearchEpisodesInteractor { query -> trendingRepository.searchEpisodes(query) }
}
