package studio.goodegg.capsule.domain.playback

import studio.goodegg.capsule.Episode
import studio.goodegg.capsule.EpisodeAndPlayState
import studio.goodegg.capsule.formatTimeRemaining

data class EpisodeAndPosition(
    val episode: Episode,
    val currentPosition: Long,
    val progress: Float,
) {
    fun formatTimeRemaining(): String = episode.formatTimeRemaining(currentPosition)
}

fun interface GetLastPlayedInteractor {
    suspend operator fun invoke(): EpisodeAndPlayState?
}