package studio.goodegg.capsule.base.main

import com.slack.circuit.foundation.Circuit
import me.tatarka.inject.annotations.Inject
import me.tatarka.inject.annotations.Provides
import studio.goodegg.capsule.common.di.ActivityScope
import studio.goodegg.capsule.details.podcast.PodcastDetailsPresenterFactory
import studio.goodegg.capsule.details.podcast.PodcastDetailsScreenUiFactory
import studio.goodegg.capsule.domain.playback.GetLastPlayedInteractor
import studio.goodegg.capsule.domain.playback.GetNowPlayingInteractor
import studio.goodegg.capsule.domain.playback.MarkAsPlayedInteractor
import studio.goodegg.capsule.domain.playback.ObserveInProgressInteractor
import studio.goodegg.capsule.domain.playback.ObserveNowPlayingInteractor
import studio.goodegg.capsule.domain.playback.ObservePlayProgressInteractor
import studio.goodegg.capsule.domain.playback.ObservePlayStateInteractor
import studio.goodegg.capsule.domain.playback.PauseInteractor
import studio.goodegg.capsule.domain.playback.PlayInteractor
import studio.goodegg.capsule.domain.playback.PlaybackDomainComponent
import studio.goodegg.capsule.domain.playback.ResumePlaybackInteractor
import studio.goodegg.capsule.domain.playback.StopInteractor
import studio.goodegg.capsule.domain.playback.UnMarkAsPlayedInteractor
import studio.goodegg.capsule.domain.podcast.GetPodcastInteractor
import studio.goodegg.capsule.domain.podcast.ObserveEpisodesInteractor
import studio.goodegg.capsule.domain.podcast.ObservePodcastInteractor
import studio.goodegg.capsule.domain.podcast.PodcastDomainComponent
import studio.goodegg.capsule.domain.search.SearchDomainComponent
import studio.goodegg.capsule.domain.search.SearchInteractor
import studio.goodegg.capsule.domain.subscription.AddSubscriptionInteractor
import studio.goodegg.capsule.domain.subscription.DeleteSubscriptionInteractor
import studio.goodegg.capsule.domain.subscription.ObserveAllSubscriptionsInteractor
import studio.goodegg.capsule.domain.subscription.ObserveSubscriptionInteractor
import studio.goodegg.capsule.domain.subscription.SubscriptionDomainComponent
import studio.goodegg.capsule.domain.trending.GetTrendingInteractor
import studio.goodegg.capsule.domain.trending.TrendingDomainComponent
import studio.goodegg.capsule.inbox.InboxPresenter
import studio.goodegg.capsule.inbox.InboxPresenterFactory
import studio.goodegg.capsule.inbox.InboxScreenUiFactory
import studio.goodegg.capsule.listen.ListenPresenterFactory
import studio.goodegg.capsule.listen.ListenScreenUiFactory
import studio.goodegg.capsule.player.PlayerPresenterFactory
import studio.goodegg.capsule.player.PlayerScreenUiFactory
import studio.goodegg.capsule.resume.ResumePresenterFactory
import studio.goodegg.capsule.resume.ResumeScreenUiFactory
import studio.goodegg.capsule.search.SearchPresenterFactory
import studio.goodegg.capsule.search.SearchScreenUiFactory
import studio.goodegg.capsule.subscriptions.SubscriptionsPresenterFactory
import studio.goodegg.capsule.subscriptions.SubscriptionsScreenUiFactory
import studio.goodegg.capsule.trending.TrendingPresenterFactory
import studio.goodegg.capsule.trending.TrendingScreenUiFactory

interface SharedUiComponent :
    SubscriptionDomainComponent,
    TrendingDomainComponent,
    SearchDomainComponent,
    PodcastDomainComponent,
    PlaybackDomainComponent {

    @Inject
    @Provides
    @ActivityScope
    fun providesCircuit(
        inboxPresenterFactory: InboxPresenterFactory,
        trendingPresenterFactory: TrendingPresenterFactory,
        subscriptionsPresenterFactory: SubscriptionsPresenterFactory,
        playerPresenterFactory: PlayerPresenterFactory,
        listenPresenterFactory: ListenPresenterFactory,
        podcastDetailsPresenterFactory: PodcastDetailsPresenterFactory,
        resumePresenterFactory: ResumePresenterFactory,
        searchPresenterFactory: SearchPresenterFactory,
    ): Circuit {
        val circuit =
            Circuit.Builder()
                .addUiFactories(
                    listOf(
                        InboxScreenUiFactory(),
                        SubscriptionsScreenUiFactory(),
                        TrendingScreenUiFactory(),
                        PodcastDetailsScreenUiFactory(),
                        ListenScreenUiFactory(),
                        SearchScreenUiFactory(),
                        PlayerScreenUiFactory(),
                        ResumeScreenUiFactory(),
                    ),
                )
                .addPresenterFactories(
                    listOf(
                        inboxPresenterFactory,
                        trendingPresenterFactory,
                        subscriptionsPresenterFactory,
                        playerPresenterFactory,
                        listenPresenterFactory,
                        podcastDetailsPresenterFactory,
                        searchPresenterFactory,
                        resumePresenterFactory,
                    ),
                )
                .build()

        return circuit
    }
}
