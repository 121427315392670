package io.daio.bass

import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.channels.awaitClose
import kotlinx.coroutines.currentCoroutineContext
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.callbackFlow
import kotlinx.coroutines.flow.flatMapLatest
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.flowOf
import kotlinx.coroutines.isActive
import kotlinx.coroutines.launch
import org.w3c.dom.events.EventListener

@OptIn(ExperimentalCoroutinesApi::class)
actual fun BassPlayer.playbackStateChanges(): Flow<PlayerState> {
    return audioPlayer.flatMapLatest {
        val audio = it ?: return@flatMapLatest flowOf(PlayerState.Idle)

        callbackFlow<PlayerState> {
            fun emit(playerState: PlayerState) {
                launch {
                    send(playerState)
                }
            }

            val listeners =
                mapOf(
                    "playing" to EventListener { emit(PlayerState.Playing) },
                    "pause" to EventListener { emit(PlayerState.Paused) },
                    "ended" to EventListener { emit(PlayerState.Stopped) },
                    "loadstart" to EventListener { emit(PlayerState.Buffering) },
                    "error" to EventListener { emit(PlayerState.Error) },
                )

            listeners.forEach {
                audio.addEventListener(it.key, it.value)
            }

            awaitClose {
                listeners.forEach {
                    audio.removeEventListener(it.key, it.value)
                }
            }
        }
    }
}

@OptIn(ExperimentalCoroutinesApi::class)
actual fun BassPlayer.playbackProgressChanges(): Flow<PlaybackProgress> {
    return audioPlayer.flatMapLatest {
        val audio = it ?: return@flatMapLatest flowOf(PlaybackProgress(0, 0, 0f))

        flow {
            while (currentCoroutineContext().isActive) {
                delay(1000)
                val duration = audio.duration.toLong()
                val progress = audio.currentTime.toLong()
                val percentage = audio.currentTime.times(100).div(audio.duration).div(100).toFloat()
                emit(PlaybackProgress(duration, progress, percentage)) // todo
            }
        }
    }
}
