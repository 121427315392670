package studio.goodegg.capsule.repositories

import app.cash.sqldelight.async.coroutines.awaitAsList
import app.cash.sqldelight.async.coroutines.awaitAsOneOrNull
import app.cash.sqldelight.coroutines.asFlow
import app.cash.sqldelight.coroutines.mapToList
import app.cash.sqldelight.coroutines.mapToOneOrNull
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.ExperimentalCoroutinesApi
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.async
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.distinctUntilChanged
import kotlinx.coroutines.flow.flatMapMerge
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.flowOn
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.Episode
import studio.goodegg.capsule.Podcast
import studio.goodegg.capsule.api.clients.PodcastBatch
import studio.goodegg.capsule.api.clients.PodcastClient
import studio.goodegg.capsule.common.coroutines.IoDispatcher
import studio.goodegg.capsule.db.CapsuleDbCreator
import studio.goodegg.capsule.db.Dbepisode
import studio.goodegg.capsule.db.Dbpodcast
import studio.goodegg.capsule.db.asFlow
import studio.goodegg.capsule.utils.Failed
import studio.goodegg.capsule.utils.Success

interface PodcastRepository {
    suspend fun getPodcast(slug: String): Podcast?
    suspend fun getEpisode(slug: String): Episode?
    fun observePodcast(slug: String): Flow<Podcast?>
    fun observeEpisodes(parentSlugs: List<String>, limit: Int): Flow<List<Episode>>
}

@Inject
internal class PodcastRepositoryImpl(
    private val capsuleDbCreator: CapsuleDbCreator,
    private val client: PodcastClient,
    @IoDispatcher private val ioDispatcher: CoroutineDispatcher,
) : PodcastRepository {

    @OptIn(ExperimentalCoroutinesApi::class)
    private val updateScope = CoroutineScope(
        SupervisorJob() + ioDispatcher.limitedParallelism(1),
    )

    private suspend fun queries() = capsuleDbCreator.get().podcastQueries

    override suspend fun getPodcast(slug: String): Podcast? = withContext(ioDispatcher) {
        val episodes = async {
            queries().selectPodcastEpisodes(slug).awaitAsList()
                .map {
                    it.toEpisode()
                }
        }

        val dbPodcast = queries().selectPodcast(slug)
            .awaitAsOneOrNull()?.toPodcast(episodes.await())

        if (dbPodcast != null) {
            updateScope.launch {
                refreshPodcast(slug)
            }
            return@withContext dbPodcast
        }

        when (val response = client.getPodcast(slug)) {
            is Failed -> null
            is Success -> response.data.also {
                updateScope.launch {
                    storeResults(it)
                }
            }
        }
    }

    override suspend fun getEpisode(slug: String): Episode? = withContext(ioDispatcher) {
        val dbEpisode = queries().selectEpisode(slug)
            .awaitAsOneOrNull()

        if (dbEpisode != null) {
            updateScope.launch {
                refreshEpisode(dbEpisode)
            }
            return@withContext dbEpisode.toEpisode()
        }

        when (val response = client.getEpisode(slug)) {
            is Failed -> null
            is Success -> response.data
        }
    }

    @OptIn(ExperimentalCoroutinesApi::class)
    override fun observePodcast(slug: String): Flow<Podcast?> {
        return capsuleDbCreator.asFlow {
            combine(
                podcastQueries.selectPodcast(slug)
                    .asFlow()
                    .mapToOneOrNull(ioDispatcher)
                    .distinctUntilChanged(),
                podcastQueries.selectPodcastEpisodes(slug)
                    .asFlow()
                    .mapToList(ioDispatcher)
                    .distinctUntilChanged(),
            ) { podcastResults, episodesResults ->
                if (podcastResults == null)
                    return@combine null

                val episodes = episodesResults.map { result ->
                    result.toEpisode()
                }

                podcastResults.toPodcast(episodes)
            }.distinctUntilChanged()
                .flatMapMerge {
                    flow {
                        emit(it)
                        refreshPodcast(slug)
                    }
                }
                .flowOn(ioDispatcher)
        }
    }

    @OptIn(ExperimentalCoroutinesApi::class)
    override fun observeEpisodes(parentSlugs: List<String>, limit: Int): Flow<List<Episode>> {
        return capsuleDbCreator.asFlow {
            podcastQueries.selectListEpisodes(parentSlugs, limit.toLong())
                .asFlow()
                .mapToList(ioDispatcher)
                .distinctUntilChanged()
                .map { dbEpisodes ->
                    dbEpisodes.map { it.toEpisode() }.distinctBy { it.episodeSlug }
                }
                .distinctUntilChanged()
                .flatMapMerge {
                    flow {
                        emit(it)

                        val batches = it.distinctBy { it.parentSlug }
                            .mapNotNull { episode ->
                                episode.parentSlug?.let {
                                    PodcastBatch(it, episode.created)
                                }
                            }

                        val batchesSlugs = batches.map { it.slug }.toSet()
                        val missing = parentSlugs.filter { it !in batchesSlugs }.map {
                            PodcastBatch(it, 0)
                        }

                        refreshEpisodes(batches + missing)
                    }
                }
                .flowOn(ioDispatcher)
        }
    }

    private suspend fun refreshPodcast(slug: String) = withContext(ioDispatcher) {
        val podcast = client.getPodcast(slug) as? Success

        if (podcast != null)
            storeResults(podcast.data)
    }

    private suspend fun refreshEpisode(episode: Dbepisode) = withContext(ioDispatcher) {
        val fetchEpisode = client.getEpisode(episode.episodeSlug) as? Success
        if (fetchEpisode != null)
            storeEpisode(fetchEpisode.data, episode.parentFeed, episode.parent_slug)
    }

    private suspend fun refreshEpisodes(podcasts: List<PodcastBatch>) = withContext(ioDispatcher) {
        val fetchEpisode = client.getPodcastsBatch(podcasts) as? Success
        fetchEpisode?.data?.forEach { podcast ->
            storeResults(podcast)
        }
    }

    private suspend fun storeResults(podcast: Podcast) = withContext(ioDispatcher) {
        queries().transaction {
            queries().insertPodcast(
                podcast.slug,
                podcast.id.toLong(),
                podcast.title,
                podcast.author,
                podcast.feed,
                podcast.collectionId.toLong(),
                podcast.artistId?.toLong(),
                podcast.thumbnail!!,
                podcast.imageUrl,
                podcast.trackCount.toLong(),
                podcast.primaryGenreName,
                podcast.summary,
                podcast.primaryGenreName,
                podcast.feedImage,
            )
            podcast.episodes.forEach { episode ->
                storeEpisode(episode, podcast.feed, podcast.slug)
            }
        }
    }

    private suspend fun storeEpisode(
        episode: Episode,
        parentFeed: String,
        parentSlug: String,
    ) = withContext(ioDispatcher) {
        queries().insertEpisode(
            episode.episodeSlug,
            episode.title,
            episode.author,
            episode.created,
            episode.description,
            episode.image,
            episode.media,
            episode.mediaType,
            episode.fileSize.toLong(),
            episode.duration,
            episode.parentFeed ?: parentFeed,
            episode.parentSlug ?: parentSlug,
        )
    }
}

private fun Dbpodcast.toPodcast(episodes: List<Episode> = emptyList()) = Podcast(
    slug = slug,
    id = id.toInt(),
    title = title,
    author = author,
    feed = feed,
    collectionId = collectionId.toInt(),
    artistId = artistId?.toInt(),
    thumbnail = thumbnail,
    imageUrl = imageUrl,
    feedImage = imageUrl,
    trackCount = trackCount?.toInt() ?: 0,
    primaryGenreName = primaryGenreName,
    summary = summary,
    episodes = episodes,
)

private fun Dbepisode.toEpisode() = Episode(
    episodeSlug = episodeSlug,
    title = title,
    author = author,
    created = created,
    description = description,
    image = image ?: "",
    media = media,
    mediaType = mediaType,
    fileSize = fileSize?.toInt() ?: 0,
    duration = duration ?: 0,
    parentFeed = parentFeed,
    parentSlug = parent_slug,
    episodeType = "full", // TODO add to db
)