package studio.goodegg.capsule

import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.datetime.format
import kotlinx.datetime.format.DateTimeComponents
import kotlinx.datetime.format.MonthNames
import kotlinx.datetime.format.char
import kotlinx.serialization.Serializable
import kotlin.time.DurationUnit
import kotlin.time.toDuration

@Serializable
data class Podcast(
    val id: Int,
    val title: String,
    val imageUrl: String,
    val author: String,
    val feed: String,
    val collectionId: Int,
    val slug: String,
    val artistId: Int? = null,
    val thumbnail: String? = null,
    val feedImage: String? = null,
    val trackCount: Int,
    val primaryGenreName: String,
    val summary: String,
    val episodes: List<Episode> = emptyList(),
)

@Serializable
data class Episode(
    val title: String,
    val image: String?,
    val author: String,
    val created: Long,
    val description: String? = null,
    val media: String,
    val fileSize: Int,
    val duration: Long,
    val mediaType: String,
    val parentFeed: String? = null,
    val parentSlug: String? = null,
    val episodeSlug: String,
    val episodeType: String,
)

fun Episode.formatCreatedDate(): String {
    val now = Clock.System.now()
    val duration = (now.toEpochMilliseconds() - created).toDuration(DurationUnit.MILLISECONDS)

    val days = duration.inWholeDays
    if (days > 90) {
        val dateFormat = DateTimeComponents.Format {
            dayOfMonth()
            char(' ')
            monthName(MonthNames.ENGLISH_ABBREVIATED)
            char(' ')
            year()
        }

        return Instant.fromEpochMilliseconds(created).format(dateFormat)
    }

    if (days > 0) {
        return "$days day${if (days > 1) "s" else ""} ago"
    }

    val hours = duration.inWholeHours
    if (hours > 0) {
        return "$hours hour${if (hours > 1) "s" else ""} ago"
    }

    val minutes = duration.inWholeMinutes.coerceAtLeast(1)
    return "$minutes minute${if (minutes > 1) "s" else ""} ago"
}

fun Episode.formatDuration(): String {
    val duration = this.duration.toDuration(DurationUnit.MILLISECONDS)
    val hours = duration.inWholeHours
    val minutes = duration.inWholeMinutes % 60
    val seconds = duration.inWholeSeconds % 60

    return if (hours > 0)
        "${hours}h ${minutes}m ${seconds}s"
    else if (minutes > 0)
        "${minutes}m ${seconds}s"
    else if (seconds > 0)
        "${seconds}s"
    else
        ""
}

fun Episode.formatTimeRemaining(currentPosition: Long): String {
    val remaining = this.duration - currentPosition
    val duration = remaining.toDuration(DurationUnit.MILLISECONDS)

    val hours = duration.inWholeHours
    val minutes = duration.inWholeMinutes % 60

    return if (hours > 0)
        "${hours}h ${minutes}m"
    else if (minutes > 0)
        "${minutes}m"
    else
        ""
}

data class EpisodeAndPlayState(
    val episode: Episode,
    val currentPosition: Long,
    val progress: Float,
    val playState: PlayState,
) {
    fun formatTimeRemaining(): String = episode.formatTimeRemaining(currentPosition)
}