package studio.goodegg.capsule.player

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.slideInVertically
import androidx.compose.animation.slideOutVertically
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.size
import androidx.compose.material3.LinearProgressIndicator
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.unit.dp
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.screen.Screen
import com.slack.circuit.runtime.ui.Ui
import com.slack.circuit.runtime.ui.ui
import compose.icons.FeatherIcons
import compose.icons.feathericons.Pause
import compose.icons.feathericons.Play
import io.daio.pancake.components.loading.LoadingIndicator
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyText
import io.daio.pancake.foundations.text.LinkText
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.Stack
import io.daio.pancake.layout.Tier
import studio.goodegg.capsule.PlayState
import studio.goodegg.capsule.navigation.PlayerScreen
import studio.goodegg.capsule.ui.components.ArtworkTile
import studio.goodegg.capsule.ui.components.OutlineIconButton

class PlayerScreenUiFactory : Ui.Factory {
    override fun create(
        screen: Screen,
        context: CircuitContext,
    ): Ui<*>? {
        return when (screen) {
            is PlayerScreen -> player()
            else -> null
        }
    }
}

private fun player() =
    ui<PlayerUiState> { state, modifier ->
        Player(
            state,
            modifier,
        )
    }

@Composable
fun Player(
    state: PlayerUiState,
    modifier: Modifier = Modifier,
) {
    AnimatedVisibility(
        visible = state.nowPlaying != null,
        enter = slideInVertically { 500 },
        exit = slideOutVertically { 500 },
    ) {
        Stack(spaceBetween = LayoutGap.None) {
            LinearProgressIndicator(
                progress = { state.nowPlaying?.progress ?: 0f },
                modifier = Modifier.height(3.dp).fillMaxWidth(),
                color = Theme.colors.accent,
                trackColor = Theme.colors.accent.copy(alpha = .4f),
            )
            Box(
                modifier = modifier
                    .fillMaxWidth()
                    .height(80.dp),
                contentAlignment = Alignment.TopStart,
            ) {
                Tier(
                    spaceBetween = LayoutGap.Tiny,
                    horizontalAlignment = Alignment.Start,
                    modifier = Modifier.align(Alignment.CenterStart)
                        .fillMaxWidth(),
                ) {
                    Box(
                        modifier = Modifier.align(Alignment.Top),
                        contentAlignment = Alignment.Center,
                    ) {
                        if (state.nowPlaying?.playState == PlayState.Buffering) {
                            LoadingIndicator(modifier = Modifier.size(24.dp))
                        }

                        ArtworkTile(
                            state.nowPlaying?.episode?.image,
                            size = 50.dp,
                            modifier = Modifier.graphicsLayer {
                                alpha =
                                    if (state.nowPlaying?.playState == PlayState.Buffering) .4f else 1f
                            },
                        )
                    }
                    Stack(modifier = Modifier.weight(1f), spaceBetween = LayoutGap.Tiny) {
                        BodyText(state.nowPlaying?.episode?.title.orEmpty(), maxLines = 2)
                        LinkText(state.nowPlaying?.episode?.author.orEmpty(), maxLines = 1)
                    }

                    val playIcon =
                        if (state.nowPlaying?.playState == PlayState.Playing) FeatherIcons.Pause
                        else FeatherIcons.Play

                    PlayerButton(
                        icon = playIcon,
                        onClick = {
                            if (state.nowPlaying?.playState == PlayState.Playing)
                                state.eventSink(PlayerUiEvent.Pause)
                            else
                                state.eventSink(PlayerUiEvent.Play)
                        },
                    )
                }
            }
        }
    }
}

@Composable
private fun PlayerButton(
    icon: ImageVector,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
) {
    OutlineIconButton(
        modifier = modifier.height(40.dp),
        icon = icon,
        onClick = onClick,
    )
}
