package studio.goodegg.capsule.resume

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.Navigator
import com.slack.circuit.runtime.presenter.Presenter
import com.slack.circuit.runtime.screen.Screen
import kotlinx.coroutines.launch
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.PlayState
import studio.goodegg.capsule.domain.playback.MarkAsPlayedInteractor
import studio.goodegg.capsule.domain.playback.ObserveInProgressInteractor
import studio.goodegg.capsule.domain.playback.ObserveNowPlayingInteractor
import studio.goodegg.capsule.domain.playback.PauseInteractor
import studio.goodegg.capsule.domain.playback.PlayInteractor
import studio.goodegg.capsule.domain.playback.ResumePlaybackInteractor
import studio.goodegg.capsule.domain.playback.UnMarkAsPlayedInteractor
import studio.goodegg.capsule.navigation.ResumeScreen

@Inject
class ResumePresenterFactory(
    private val playInteractor: PlayInteractor,
    private val observeNowPlaying: ObserveNowPlayingInteractor,
    private val pauseInteractor: PauseInteractor,
    private val resumePlaybackInteractor: ResumePlaybackInteractor,
    private val observeInProgressInteractor: ObserveInProgressInteractor,
    private val markAsPlayedInteractor: MarkAsPlayedInteractor,
    private val unMarkAsPlayedInteractor: UnMarkAsPlayedInteractor,
) : Presenter.Factory {
    override fun create(
        screen: Screen,
        navigator: Navigator,
        context: CircuitContext,
    ): Presenter<*>? {
        return when (screen) {
            is ResumeScreen -> ResumePresenter(
                navigator = navigator,
                playInteractor = playInteractor,
                observeNowPlaying = observeNowPlaying,
                pauseInteractor = pauseInteractor,
                resumePlaybackInteractor = resumePlaybackInteractor,
                observeInProgressInteractor = observeInProgressInteractor,
                markAsPlayedInteractor = markAsPlayedInteractor,
                unMarkAsPlayedInteractor = unMarkAsPlayedInteractor,
            )

            else -> null
        }
    }
}

class ResumePresenter(
    private val navigator: Navigator,
    private val playInteractor: PlayInteractor,
    private val observeNowPlaying: ObserveNowPlayingInteractor,
    private val pauseInteractor: PauseInteractor,
    private val resumePlaybackInteractor: ResumePlaybackInteractor,
    private val observeInProgressInteractor: ObserveInProgressInteractor,
    private val markAsPlayedInteractor: MarkAsPlayedInteractor,
    private val unMarkAsPlayedInteractor: UnMarkAsPlayedInteractor,
) : Presenter<ResumeUiState> {
    @Composable
    override fun present(): ResumeUiState {

        val scope = rememberCoroutineScope()
        val episodes by remember { observeInProgressInteractor() }.collectAsState(emptyList())
        val nowPlaying by remember { observeNowPlaying() }.collectAsState(null)

        fun eventSink(event: ResumeUiEvent) {
            when (event) {
                is ResumeUiEvent.MarkAsPlayed -> scope.launch {
                    markAsPlayedInteractor(event.episode)
                }

                is ResumeUiEvent.Open -> TODO()
                is ResumeUiEvent.Resume -> scope.launch {
                    if (nowPlaying?.episode?.episodeSlug == event.episode.episode.episodeSlug) {
                        when (nowPlaying?.playState) {
                            PlayState.Buffering, PlayState.Playing -> pauseInteractor()
                            PlayState.Paused -> resumePlaybackInteractor()
                            else -> Unit
                        }
                    } else {
                        playInteractor(event.episode.episode, event.episode.currentPosition)
                    }
                }

                is ResumeUiEvent.UnMarkAsPlayed -> scope.launch {
                    unMarkAsPlayedInteractor(event.episode)
                }
            }
        }

        return ResumeUiState(
            items = episodes,
            nowPlaying = nowPlaying,
            eventSink = ::eventSink,
        )
    }
}
