package studio.goodegg.capsule.inbox

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import com.slack.circuit.retained.collectAsRetainedState
import com.slack.circuit.retained.rememberRetained
import com.slack.circuit.runtime.CircuitContext
import com.slack.circuit.runtime.Navigator
import com.slack.circuit.runtime.presenter.Presenter
import com.slack.circuit.runtime.screen.Screen
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.onStart
import me.tatarka.inject.annotations.Inject
import studio.goodegg.capsule.PlayState
import studio.goodegg.capsule.domain.playback.ObserveAllPlayedInteractor
import studio.goodegg.capsule.domain.playback.ObserveNowPlayingInteractor
import studio.goodegg.capsule.domain.playback.PauseInteractor
import studio.goodegg.capsule.domain.playback.PlayInteractor
import studio.goodegg.capsule.domain.playback.ResumePlaybackInteractor
import studio.goodegg.capsule.domain.podcast.ObserveEpisodesInteractor
import studio.goodegg.capsule.domain.subscription.ObserveAllSubscriptionsInteractor
import studio.goodegg.capsule.navigation.InboxScreen
import studio.goodegg.capsule.navigation.PodcastDetailsScreen
import studio.goodegg.capsule.navigation.SubscriptionsScreen

@Inject
class InboxPresenterFactory(
    private val observeAllSubscriptionsInteractor: ObserveAllSubscriptionsInteractor,
    private val observeEpisodesInteractor: ObserveEpisodesInteractor,
    private val observeNowPlaying: ObserveNowPlayingInteractor,
    private val pauseInteractor: PauseInteractor,
    private val resumePlaybackInteractor: ResumePlaybackInteractor,
    private val playInteractor: PlayInteractor,
    private val observeAllPlayedInteractor: ObserveAllPlayedInteractor,
) : Presenter.Factory {
    override fun create(
        screen: Screen,
        navigator: Navigator,
        context: CircuitContext,
    ): Presenter<*>? {
        return when (screen) {
            is InboxScreen -> InboxPresenter(
                navigator,
                observeAllSubscriptionsInteractor,
                observeEpisodesInteractor,
                observeNowPlaying,
                pauseInteractor,
                resumePlaybackInteractor,
                playInteractor,
                observeAllPlayedInteractor,
            )

            else -> null
        }
    }
}

class InboxPresenter(
    private val navigator: Navigator,
    private val observeAllSubscriptionsInteractor: ObserveAllSubscriptionsInteractor,
    private val observeEpisodesInteractor: ObserveEpisodesInteractor,
    private val observeNowPlaying: ObserveNowPlayingInteractor,
    private val pauseInteractor: PauseInteractor,
    private val resumePlaybackInteractor: ResumePlaybackInteractor,
    private val playInteractor: PlayInteractor,
    private val observeAllPlayedInteractor: ObserveAllPlayedInteractor,
) : Presenter<InboxUiState> {
    @Composable
    override fun present(): InboxUiState {
        val subscriptions by rememberRetained {
            observeAllSubscriptionsInteractor()
        }.collectAsRetainedState(emptyList())

        val episodes by rememberRetained(subscriptions) {
            observeEpisodesInteractor.invoke(subscriptions.map { it.slug }, 40)
                .combine(observeAllPlayedInteractor().onStart { emit(emptyList()) },
                ) { episodes, played ->
                    val playedSlugs = played.map { it.slug }
                    episodes.filterNot { playedSlugs.contains(it.episodeSlug) }
                }
        }.collectAsRetainedState(emptyList())

        val nowPlaying by remember { observeNowPlaying() }.collectAsState(null)

        fun eventSink(event: InboxUiEvent) {
            when (event) {
                is InboxUiEvent.Open -> navigator.goTo(PodcastDetailsScreen(event.slug))
                InboxUiEvent.ViewAllSubscriptions -> navigator.resetRoot(SubscriptionsScreen)
                is InboxUiEvent.PlayEpisode -> {
                    if (nowPlaying?.episode?.episodeSlug == event.episode.episodeSlug) {
                        when (nowPlaying?.playState) {
                            PlayState.Buffering, PlayState.Playing -> pauseInteractor()
                            PlayState.Paused -> resumePlaybackInteractor()
                            else -> Unit
                        }
                    } else {
                        playInteractor(event.episode, 0)
                    }
                }

                is InboxUiEvent.DownloadEpisode -> {

                }

                is InboxUiEvent.ShareEpisode -> {

                }
            }
        }


        return InboxUiState(
            subscriptions = subscriptions,
            latestEpisodes = episodes,
            nowPlaying = nowPlaying,
            eventSink = ::eventSink,
        )
    }
}
