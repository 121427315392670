package studio.goodegg.capsule.db

import app.cash.sqldelight.SuspendingTransacter
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlDriver
import app.cash.sqldelight.db.SqlSchema
import kotlin.Unit
import studio.goodegg.capsule.db.db.newInstance
import studio.goodegg.capsule.db.db.schema

public interface CapsuleDb : SuspendingTransacter {
  public val playedQueries: PlayedQueries

  public val podcastQueries: PodcastQueries

  public val subscriptionQueries: SubscriptionQueries

  public companion object {
    public val Schema: SqlSchema<QueryResult.AsyncValue<Unit>>
      get() = CapsuleDb::class.schema

    public operator fun invoke(driver: SqlDriver): CapsuleDb = CapsuleDb::class.newInstance(driver)
  }
}
