package studio.goodegg.capsule.db

import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlDriver
import app.cash.sqldelight.db.SqlSchema
import kotlinx.coroutines.sync.Mutex
import kotlinx.coroutines.sync.withLock

actual class CapsuleDbCreator(
    private val schema: SqlSchema<QueryResult.AsyncValue<Unit>>,
    private val driver: SqlDriver,
) {
    private val mutex = Mutex()
    private var db: CapsuleDb? = null

    actual suspend fun get(): CapsuleDb {
        db?.let { return it }
        return mutex.withLock {
            schema.create(driver).await()
            db = CapsuleDb(driver)
            db!!
        }
    }
}