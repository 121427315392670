

// Do not edit directly
// Generated on Fri, 07 Jun 2024 06:18:18 GMT

package io.daio.pancake.foundations.color

import androidx.compose.ui.graphics.Color

object ThemeColors {
    val themeDarkAccent = Color(0xffff7f50)
    val themeDarkInformNegative = Color(0xffd64933)
    val themeDarkInformPositive = Color(0xff7ea77b)
    val themeDarkInformWarning = Color(0xfff4d35e)
    val themeDarkOnSurfaceAccent = Color(0xff1c1c1c)
    val themeDarkOnSurfaceBackground = Color(0xfff4f4f4)
    val themeDarkOnSurfaceBold = Color(0xfff4f4f4)
    val themeDarkOnSurfaceContainer = Color(0xfff4f4f4)
    val themeDarkOnSurfaceInteractive = Color(0xff1c1c1c)
    val themeDarkSurfaceBackground = Color(0xff1c1c1c)
    val themeDarkSurfaceBold = Color(0xff1c1c1c)
    val themeDarkSurfaceContainer = Color(0xff323232)
    val themeDarkSurfaceInteractive = Color(0xfff4f4f4)
    val themeLightAccent = Color(0xff8b4513)
    val themeLightInformNegative = Color(0xffd64933)
    val themeLightInformPositive = Color(0xff7ea77b)
    val themeLightInformWarning = Color(0xfff4d35e)
    val themeLightOnSurfaceAccent = Color(0xfff4f4f4)
    val themeLightOnSurfaceBackground = Color(0xff1c1c1c)
    val themeLightOnSurfaceBold = Color(0xff1c1c1c)
    val themeLightOnSurfaceContainer = Color(0xff1c1c1c)
    val themeLightOnSurfaceInteractive = Color(0xfff4f4f4)
    val themeLightSurfaceBackground = Color(0xfff4f4f4)
    val themeLightSurfaceBold = Color(0xffd6d6d6)
    val themeLightSurfaceContainer = Color(0xffebebeb)
    val themeLightSurfaceInteractive = Color(0xff1c1c1c)
}
