package studio.goodegg.capsule

data class PodcastSubscription(
    val slug: String,
    val feedUrl: String,
    val title: String,
    val artist: String,
    val image: String?,
    val notify: Boolean,
    val collection: String?,
    val autoDownload: Boolean,
)
