package io.daio.bass

interface BassPlayer {
    val currentItem: MediaItem?
    val currentPosition: Long

    fun play(media: MediaItem)

    fun playlist(media: List<MediaItem>)

    fun stop()

    fun pause()

    fun resume()

    fun seekByDelta(deltaMs: Long)

    fun setPlaybackSpeed(speed: Float)

    fun release()

    fun next()

    fun hasNext(): Boolean

    fun previous()

    fun hasPrevious(): Boolean

    fun interface Factory {
        /**
         * Create a new [BassPlayer]
         */
        fun create(context: BassContext): BassPlayer
    }

    class Builder(private val context: BassContext) {
        private val playerOptions = BassPlayerOptions()

        internal fun withOption(
            key: String,
            value: Any,
        ) {
            playerOptions.put(key, value)
        }

        fun build(): BassPlayer {
            return createPlayer(context, playerOptions)
        }
    }
}

internal class DefaultPlayerFactory : BassPlayer.Factory {
    override fun create(context: BassContext): BassPlayer {
        return BassPlayer.Builder(context).build()
    }
}

val BassContext.bassPlayer: BassPlayer
    get() = SingletonBassPlayer.getPlayer(this)
