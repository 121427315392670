package studio.goodegg.capsule.ui.components

import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.material3.Icon
import androidx.compose.material3.minimumInteractiveComponentSize
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.semantics.Role
import androidx.compose.ui.semantics.role
import androidx.compose.ui.semantics.semantics
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import compose.icons.FeatherIcons
import compose.icons.feathericons.CheckCircle
import compose.icons.feathericons.DownloadCloud
import compose.icons.feathericons.Pause
import compose.icons.feathericons.Play
import compose.icons.feathericons.Share2
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyTextSmall
import io.daio.pancake.foundations.text.LinkText
import io.daio.pancake.icons.Icons
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.Stack
import io.daio.pancake.layout.Tier
import studio.goodegg.capsule.Episode
import studio.goodegg.capsule.EpisodeAndPlayState
import studio.goodegg.capsule.PlayState
import studio.goodegg.capsule.formatCreatedDate
import studio.goodegg.capsule.formatTimeRemaining

@Composable
fun EpisodeItem(
    episode: Episode,
    nowPlaying: EpisodeAndPlayState?,
    onPlay: () -> Unit,
    onDownload: () -> Unit,
    onShare: () -> Unit,
    modifier: Modifier = Modifier,
    played: Boolean = false,
) {
    Stack(
        modifier = modifier
            .wrapContentHeight()
            .fillMaxWidth(),
        spaceBetween = LayoutGap.Small,
    ) {
        Tier(verticalAlignment = Alignment.Top) {

            ArtworkTile(
                size = 68.dp,
                imageUrl = episode.image,
                modifier = Modifier.graphicsLayer {
                    alpha = if (played) .4f else 1f
                },
            )
            Stack(
                spaceBetween = LayoutGap.Tiny,
                modifier = Modifier.graphicsLayer {
                    alpha = if (played) .4f else 1f
                },
            ) {
                BodyTextSmall(episode.title, maxLines = 3, overflow = TextOverflow.Ellipsis)
                LinkText(
                    text = episode.formatCreatedDate(),
                    textColor = Theme.colors.onSurfaceBackground.copy(alpha = .8f),
                )
            }
        }
        BodyTextSmall(
            episode.description.orEmpty(),
            maxLines = 3,
            overflow = TextOverflow.Ellipsis,
            modifier = Modifier.graphicsLayer {
                alpha = if (played) .4f else 1f
            },
        )

        Tier(spaceBetween = LayoutGap.Medium) {
            val isNowPlaying = episode.episodeSlug == nowPlaying?.episode?.episodeSlug
            val isPaused = isNowPlaying && nowPlaying?.playState == PlayState.Paused

            IconTextButton(
                icon = if (isPaused || !isNowPlaying) Icons.Play else Icons.Pause,
                text = if (isPaused) "Resume" else if (isNowPlaying) "Pause" else
                    episode.formatTimeRemaining(0),
                onClick = onPlay,
            )
            Icon(
                modifier = Modifier.size(20.dp)
                    .minimumInteractiveComponentSize()
                    .clickable(onClick = onDownload)
                    .semantics { role = Role.Button },
                imageVector = FeatherIcons.DownloadCloud,
                contentDescription = "download",
                tint = Theme.colors.onSurfaceBackground.copy(alpha = .7f),
            )
            Icon(
                modifier = Modifier.size(20.dp)
                    .minimumInteractiveComponentSize()
                    .clickable(onClick = onShare)
                    .semantics { role = Role.Button },
                imageVector = FeatherIcons.Share2,
                contentDescription = "share",
                tint = Theme.colors.onSurfaceBackground.copy(alpha = .7f),
            )
            if (played) {
                Icon(
                    modifier = Modifier.size(20.dp),
                    imageVector = FeatherIcons.CheckCircle,
                    contentDescription = "played indicator",
                    tint = Theme.colors.positive,
                )
            }
        }
    }
}