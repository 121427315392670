package io.daio.pancake.foundations.text

import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextLayoutResult
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow

@Composable
fun TitleTextScreen(
    text: String,
    modifier: Modifier = Modifier,
    textColor: Color = Color.Unspecified,
    onTextLayout: ((TextLayoutResult) -> Unit)? = null,
    overflow: TextOverflow = TextOverflow.Clip,
    softWrap: Boolean = true,
    maxLines: Int = Int.MAX_VALUE,
    minLines: Int = 1,
    textAlign: TextAlign = TextAlign.Start,
) {
    BaseText(
        text = text,
        textStyle = mainTypography.titleScreen,
        textColor = textColor,
        overflow = overflow,
        softWrap = softWrap,
        maxLines = maxLines,
        minLines = minLines,
        onTextLayout = onTextLayout,
        textAlign = textAlign,
        modifier = modifier,
    )
}

@Composable
fun TitleTextLarge(
    text: String,
    modifier: Modifier = Modifier,
    textColor: Color = Color.Unspecified,
    onTextLayout: ((TextLayoutResult) -> Unit)? = null,
    overflow: TextOverflow = TextOverflow.Clip,
    softWrap: Boolean = true,
    maxLines: Int = Int.MAX_VALUE,
    minLines: Int = 1,
    textAlign: TextAlign = TextAlign.Start,
) {
    BaseText(
        text = text,
        textStyle = mainTypography.titleLarge,
        textColor = textColor,
        overflow = overflow,
        softWrap = softWrap,
        maxLines = maxLines,
        minLines = minLines,
        onTextLayout = onTextLayout,
        textAlign = textAlign,
        modifier = modifier,
    )
}

@Composable
fun TitleText(
    text: String,
    modifier: Modifier = Modifier,
    textColor: Color = Color.Unspecified,
    onTextLayout: ((TextLayoutResult) -> Unit)? = null,
    overflow: TextOverflow = TextOverflow.Clip,
    softWrap: Boolean = true,
    maxLines: Int = Int.MAX_VALUE,
    minLines: Int = 1,
    textAlign: TextAlign = TextAlign.Start,
) {
    BaseText(
        text = text,
        textStyle = mainTypography.titleDefault,
        textColor = textColor,
        overflow = overflow,
        softWrap = softWrap,
        maxLines = maxLines,
        minLines = minLines,
        onTextLayout = onTextLayout,
        textAlign = textAlign,
        modifier = modifier,
    )
}
