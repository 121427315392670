package studio.goodegg.capsule.ui.components

import androidx.compose.foundation.layout.ColumnScope
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Icon
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.vector.ImageVector
import io.daio.pancake.foundations.Theme
import io.daio.pancake.foundations.text.BodyText
import io.daio.pancake.foundations.text.BodyTextLarge
import io.daio.pancake.foundations.text.BodyTextSmall
import io.daio.pancake.layout.LayoutGap
import io.daio.pancake.layout.Stack

@Composable
fun ZeroStateLayout(
    title: String,
    subtitle: String,
    modifier: Modifier = Modifier,
    icon: ImageVector? = null,
) {
    ZeroStateLayout(
        modifier = modifier,
        title = {
            BodyText(title)
        },
        subtitle = {
            BodyTextSmall(subtitle)
        },
        icon = {
            icon?.let {
                Icon(
                    imageVector = it,
                    tint = Theme.colors.onSurfaceBackground,
                    contentDescription = "Zero State Icon",
                )
            }
        },
    )
}

@Composable
fun ZeroStateLayout(
    title: @Composable ColumnScope.() -> Unit,
    subtitle: @Composable ColumnScope.() -> Unit,
    icon: @Composable ColumnScope.() -> Unit,
    modifier: Modifier = Modifier,
) {
    Stack(
        spaceBetween = LayoutGap.Large,
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalAlignment = Alignment.CenterVertically,
        modifier =
        modifier
            .fillMaxSize()
            .padding(Theme.dimens.size_l),
    ) {
        icon()
        title()
        subtitle()
    }
}
